import React from "react";
import { ColDef } from "ag-grid-community";
import { ColumnTypeEnum, createGroupColumn } from "../../components";

export const columnDefinitions: ColDef[] = [
  createGroupColumn("Underlying", "underlying", ColumnTypeEnum.Text, {
    rowGroup: true,
    aggFunc: "distinct",
    hide: true,
  }),

  createGroupColumn("Symbol", "symbol", ColumnTypeEnum.Text, {
    rowGroup: true,
    aggFunc: "distinct",
    hide: true,
  }),

  createGroupColumn("Position Date", "positionDate", ColumnTypeEnum.Date, {
    rowGroup: true,
    aggFunc: "distinct",
    hide: true,
    sort: "desc",
    cellRendererParams: {
      dateOnly: true,
    },
  }),

  createGroupColumn("Book", "book", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
    hide: true,
  }),

  createGroupColumn("Position Group", "positionGroup", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
    hide: true,
  }),

  createGroupColumn("Trade Id", "brokerTradeId", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
    sort: "asc",
  }),

  createGroupColumn("Buy / Sell", "buySell", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
  }),

  createGroupColumn("Quantity", "quantity", ColumnTypeEnum.Numeric, {
    aggFunc: "none",
  }),

  createGroupColumn(
    "Trade Adjusted Price",
    "tradeAdjustedPrice",
    ColumnTypeEnum.Numeric,
    {
      aggFunc: "none",
      cellRendererParams: {
        noFormatting: true,
      },
    }
  ),

  createGroupColumn("Net Proceeds", "netProceeds", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn(
    "Running Quantity",
    "runningQuantity",
    ColumnTypeEnum.Numeric,
    {
      aggFunc: "none",
    }
  ),

  createGroupColumn(
    "Claimed Trade Id",
    "claimedBrokerTradeId",
    ColumnTypeEnum.Text,
    {
      aggFunc: "distinct",
    }
  ),

  createGroupColumn(
    "Claimed Quantity",
    "claimedQuantity",
    ColumnTypeEnum.Numeric,
    {
      aggFunc: "none",
    }
  ),

  createGroupColumn(
    "Claimed Trade Adjusted Price",
    "claimedTradeAdjustedPrice",
    ColumnTypeEnum.Numeric,
    {
      aggFunc: "none",
      cellRendererParams: {
        noFormatting: true,
      },
    }
  ),

  createGroupColumn("Price Diff", "priceDiff", ColumnTypeEnum.Numeric, {
    aggFunc: "none",
    cellRendererParams: {
      noFormatting: true,
    },
  }),

  createGroupColumn("Realised PnL", "realisedPnl", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn("Running Cost", "runningCost", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn(
    "Running Realised PnL",
    "runningRealisedPnl",
    ColumnTypeEnum.Currency,
    {
      cellRendererParams: { showDirection: true },
      aggFunc: "sum",
    }
  ),

  createGroupColumn("Cost Basis", "costBasis", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn(
    "Running Cost Basis",
    "runningCostBasis",
    ColumnTypeEnum.Currency,
    {
      cellRendererParams: { showDirection: true },
      aggFunc: "sum",
    }
  ),
];
