import React from "react";
import {
  Flex,
  InputGroup,
  Input,
  InputLeftAddon,
  Text,
  FormErrorMessage,
  FormControl,
  HStack,
  Button,
  VStack,
  Stack,
  Spacer,
  Divider,
} from "@chakra-ui/react";
import {
  ETradeAutoSyncControllerProps,
  useETradeAutoSyncController,
} from "./useETRADEAutoSyncController";
import { Show } from "../../../../components";
import ETradeAutoSyncInstructions from "../../../uploads/panels/BrokerUpload/instructions/EtradeAutoSyncInstructions";

// Define the component with typed props
export const ETRADE_AutoSync: React.FC<ETradeAutoSyncControllerProps> = (
  props
) => {
  // Use custom controller hook for managing the form state
  const controller = useETradeAutoSyncController(props);

  // Update API key and secret fields
  const handleAPIKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedKey = e.target.value;
    controller.setConfig({ ...controller.config, apiKey: updatedKey });
    controller.saveConfig({ ...controller.config, apiKey: updatedKey });
  };

  const handleAPISecretChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedSecret = e.target.value;
    controller.setConfig({ ...controller.config, secretKey: updatedSecret });
    controller.saveConfig({ ...controller.config, secretKey: updatedSecret });
  };

  // General reusable input rendering method for API Key/Secret
  const renderInputField = (
    label: string,
    value: string,
    isInvalid: boolean,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    readOnly: boolean
  ) => (
    <FormControl isInvalid={isInvalid}>
      <InputGroup size="sm" my={2}>
        <InputLeftAddon w="225px">{label}</InputLeftAddon>
        <Show if={readOnly}>
          <Text size="sm">{value}</Text>
        </Show>
        <Show if={!readOnly}>
          <Input
            size="sm"
            type="text"
            value={value || ""}
            onChange={onChange}
          />
        </Show>
      </InputGroup>
      {isInvalid && <FormErrorMessage>Invalid {label}</FormErrorMessage>}
    </FormControl>
  );

  return (
    <Flex direction="column" w="full">
      {/* Section 1: API Key and Secret Input */}
      <Text mt="20px" bg="var(--dark-red)" p="2" borderRadius={10}>
        By default ETrade will allow us to update your account for 1 day. After
        this you'll need to log back in using the "Get Link Code" button. It is
        possible to extend this limit to 30 days but you need to phone ETrade
        and request a "30 day persistant API access token".
      </Text>
      <Text mt="40px">1. Enter your Consumer Key and API Secret here:</Text>
      <HStack mb="40px">
        {renderInputField(
          "Consumer Key",
          controller.config.apiKey,
          !controller.validAPIKey,
          handleAPIKeyChange,
          controller.readOnly
        )}
        {renderInputField(
          "API Secret",
          controller.config.secretKey,
          !controller.validAPISecret,
          handleAPISecretChange,
          controller.readOnly
        )}
      </HStack>

      {/* Section 2: Generate Link Code */}
      <HStack>
        <Text>2. Generate link code:</Text>
        <Button
          size="sm"
          colorScheme="green"
          isDisabled={!controller.config.apiKey || !controller.config.secretKey}
          onClick={(e) => {
            e.preventDefault();
            controller.testConfig("AUTHORIZE_URL");
          }}
          ml={2}
        >
          Get link code
        </Button>
      </HStack>

      {/* Link Code Input */}
      <HStack>
        {renderInputField(
          "Link code",
          controller.config.code,
          false,
          (e) => controller.setLinkCode(e.target.value),
          controller.readOnly
        )}
      </HStack>

      {/* Section 3: Confirm Connection */}
      <Stack mt="40px">
        <Text>3. Next confirm the connection:</Text>
        <Button
          mt="10px"
          size="sm"
          colorScheme="green"
          isDisabled={
            !controller.config.apiKey ||
            !controller.config.secretKey ||
            !controller.config.code ||
            controller.connectionStatus === "CONNECTED" ||
            controller.connectionStatus === "FAILED" ||
            controller.config.accessToken
          }
          onClick={(e) => {
            e.preventDefault();
            controller.testConfig("TEST_CONNECTION");
          }}
          maxW="300px"
        >
          {controller.config.accessToken
            ? "Connected"
            : {
                CONNECTED: "Connected",
                FAILED: "Failed, retry link code",
                NOT_CONNECTED: "Test Connection",
                IN_PROGRESS: "Connecting...",
                "": "Test Connection",
              }[controller.connectionStatus]}
        </Button>
      </Stack>

      {/* Section 4: Action Buttons */}
      <HStack mb={1} mt="0px" justifyContent={"flex-end"}>
        {/* Renew Access Token Button */}
        <Button
          size="sm"
          colorScheme="red"
          disabled={!controller.validAPIKey || !controller.validAPISecret}
          onClick={(e) => {
            e.preventDefault();
            controller.testConfig("RENEW_ACCESS_TOKEN");
          }}
          ml={2}
        >
          RENEW_ACCESS_TOKEN
        </Button>

        {/* Test Data Access Button */}
        <Button
          size="sm"
          colorScheme="red"
          disabled={!controller.validAPIKey || !controller.validAPISecret}
          onClick={(e) => {
            e.preventDefault();
            controller.testConfig("TEST_DATA_ACCESS");
          }}
          ml={2}
        >
          TEST_DATA_ACCESS
        </Button>

        {/* Clear Config Button */}
        <Button
          size="sm"
          onClick={() => {
            controller.setConfig({
              apiKey: "",
              secretKey: "",
              accessToken: "",
              accessTokenSecret: "",
              oauthToken: "",
              oauthTokenSecret: "",
              code: "",
            });
            controller.saveConfig({
              apiKey: "",
              secretKey: "",
              accessToken: "",
              accessTokenSecret: "",
              oauthToken: "",
              oauthTokenSecret: "",
              code: "",
            });
          }}
          ml={2}
        >
          Clear
        </Button>

        {/* Close Button */}
        <Show if={props.close !== undefined}>
          <Button size="sm" onClick={props.close!} ml={2}>
            Close
          </Button>
        </Show>
      </HStack>

      <Divider h="50px" />
      <ETradeAutoSyncInstructions />
    </Flex>
  );
};
