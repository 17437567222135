import { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import moment from "moment-timezone";
import { ChakraProvider, Box, Center, Heading } from "@chakra-ui/react";
import theme from "./themeConfig";
import { MainApp } from "./features/main-app/MainApp";
import { Dashboard } from "./features/dashboard/Dashboard";
import { Login } from "./features/authentication/login/Login";
import FourOhFour from "./404";
import { Register } from "./features/authentication/register/Register";
import { ResetPassword } from "./features/authentication/reset/ResetPassword";
import { useAppController } from "./useAppController";
import Settings from "./features/settings/Settings";

import Positions from "./features/positions/Positions";
import Transactions from "./features/transactions/Transactions";

import ThemeSwitcher from "./components/themes/ThemeSwitcher";
import { Admin } from "./features/admin/Admin";
import React from "react";
import FormExample from "./components/forms/FormExample";
import TokenAssignment from "./features/admin/users/TokenAssignment";
import Cashflows from "./features/cashflows/Cashflows";
import { Prices } from "./features/prices/Prices";
import Calendar from "./features/calendar/Calendar";
import EmbedDashboard from "./features/iframe/EmbedDashboard";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import PayOffConfigurator from "./components/optionpayoff/PayOffConfigurator";

const Loading = () => (
  <Box>
    <Center height="100vh" flexDir="column">
      <Heading mt="2" size="md">
        Loading...
      </Heading>
    </Center>
  </Box>
);

const App = () => {
  const controller = useAppController();
  moment.tz.setDefault("Europe/London");

  const queryClient = new QueryClient();

  useEffect(() => {
    localStorage.setItem("chakra-ui-color-mode", "dark");
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <ThemeSwitcher />
      <ChakraProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          {controller.initialised && (
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route
                  index
                  path="/23rjfisf4d3-234f4-fdsfnfj4fndc/token"
                  element={<TokenAssignment />}
                />
                <Route path="/app/login" element={<Login />} />
                <Route path="/app/register" element={<Register />} />
                <Route path="/app/reset" element={<ResetPassword />} />
                <Route path="/app" element={<MainApp />}>
                  <Route index path="/app/admin" element={<Admin />} />

                  <Route
                    index
                    path="/app/admin/forms"
                    element={<FormExample />}
                  />

                  <Route index path="/app/dashboard" element={<Dashboard />} />
                  <Route index path="/app/positions" element={<Positions />} />
                  <Route
                    index
                    path="/app/options/payoff"
                    element={<PayOffConfigurator />}
                  />

                  <Route
                    index
                    path="/app/transactions"
                    element={<Transactions />}
                  />
                  <Route index path="/app/cashflows" element={<Cashflows />} />
                  <Route index path="/app/prices" element={<Prices />} />
                  <Route index path="/app/calendar" element={<Calendar />} />
                  <Route index path="/app/settings" element={<Settings />} />
                </Route>
                <Route
                  path="*"
                  element={<Navigate to="/app/dashboard" replace />}
                />
                <Route path="/404" element={<FourOhFour />} />
                <Route path="/embed" element={<EmbedDashboard />} />
              </Routes>
            </BrowserRouter>
          )}
        </QueryClientProvider>
      </ChakraProvider>
    </Suspense>
  );
};

export default App;
