import React, { useEffect, useMemo, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  Select,
  HStack,
  Text,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import moment from "moment";
import { updateTrade } from "../../../../api/Trades";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../../../store";
import { usePositions } from "../../../../hooks";
import { useTransactions } from "../../../../hooks/useTransactions";
import convertSymbolToReadable from "../convertSymbolToReadable";

interface EditTradeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  selectedParams: any;
}

const EditTradeModal: React.FC<EditTradeModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  selectedParams,
}) => {
  const trade = useMemo(
    () => selectedParams?.node?.data || {},
    [selectedParams]
  );

  const [buySell, setBuySell] = useState(trade.buySell || "Buy");
  const [commission, setCommission] = useState(trade.commission || "");
  const [fees, setFees] = useState(trade.fees || "");
  const [grossProceeds, setGrossProceeds] = useState(trade.grossProceeds || "");
  const [netProceeds, setNetProceeds] = useState(trade.netProceeds || "");
  const [price, setPrice] = useState(trade.price || "");
  const [quantity, setQuantity] = useState(trade.quantity || "");
  const [loading, setLoading] = useState<boolean>(false);

  const [tradeDate, setTradeDate] = useState(
    trade.tradeDate || new Date().toISOString().split("T")[0]
  );

  const positions = usePositions();
  const transactions = useTransactions();

  const accountState = useAtomValue(AccountAtom);

  useEffect(() => {
    if (trade) {
      setBuySell(trade.buySell || "Buy");
      setCommission(trade.commission || "0");
      setFees(trade.fees || "0");
      setGrossProceeds(trade.grossProceeds || "0");
      setNetProceeds(trade.netProceeds || "0");
      setPrice(trade.price || "0");
      setQuantity(trade.quantity || "0");
      setTradeDate(trade.tradeDate || new Date().toISOString().split("T")[0]);
    }
  }, [trade]);

  const handleNumericInput = (
    value: string,
    setter: (value: string) => void
  ) => {
    // Allow an optional "-" at the start, followed by digits and an optional decimal
    if (/^-?\d*\.?\d*$/.test(value)) {
      setter(value);
    }
  };

  useEffect(() => {
    if (!trade || !trade.instrument || !trade.instrument.multiplier) {
      console.error("Instrument or multiplier is missing in trade data");
      return;
    }

    const multiplier = parseFloat(trade.instrument.multiplier) || 1;
    const parsedPrice = parseFloat(price) || 0;
    const parsedQuantity = Math.abs(parseFloat(quantity)) || 0; // Ensure quantity is always positive
    const parsedCommission = parseFloat(commission) || 0;
    const parsedFees = parseFloat(fees) || 0;

    const normalizedQuantity =
      buySell === "Buy" ? parsedQuantity : -parsedQuantity; // Normalize based on Buy/Sell

    const calculatedGrossProceeds =
      buySell === "Buy"
        ? -Math.abs(parsedPrice * normalizedQuantity * multiplier).toFixed(2)
        : Math.abs(parsedPrice * normalizedQuantity * multiplier).toFixed(2);

    const calculatedNetProceeds = (
      parseFloat(calculatedGrossProceeds) -
      parsedCommission -
      parsedFees
    ).toFixed(2);

    // Update proceeds only if there's a change to avoid unnecessary renders
    if (calculatedGrossProceeds !== grossProceeds) {
      setGrossProceeds(calculatedGrossProceeds.toString());
    }
    if (calculatedNetProceeds !== netProceeds) {
      setNetProceeds(calculatedNetProceeds.toString());
    }

    // Update quantity state to reflect normalization
    if (normalizedQuantity.toString() !== quantity) {
      setQuantity(normalizedQuantity.toString());
    }
  }, [
    buySell,
    commission,
    fees,
    price,
    quantity,
    trade,
    grossProceeds,
    netProceeds,
  ]);
  const handleConfirm = () => {
    setLoading(true);
    updateTrade(
      trade.id,
      {
        ...trade,
        buySell,
        commission: isNaN(parseFloat(commission)) ? 0 : parseFloat(commission),
        fees: isNaN(parseFloat(fees)) ? 0 : parseFloat(fees),
        grossProceeds: parseFloat(grossProceeds),
        netProceeds: parseFloat(netProceeds),
        price: parseFloat(price),
        quantity: parseFloat(quantity),
        tradeDate,
      },
      accountState.selectedAccount.id
    ).then((res) => {
      if (res.success === true) {
        positions.fetchPositions(accountState.selectedAccount.id);
        transactions.getTradesAndCashflows(accountState.selectedAccount.id);
        setLoading(false);

        onConfirm();
      } else {
        setLoading(false);
        console.log("Failed to update trade");
        alert("Failed to update trade, please check the entry fields...");
      }
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="var(--bg)">
        <ModalHeader>Edit Trade</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack mb="20px" alignItems={"flex-start"}>
            <Text>
              {convertSymbolToReadable(trade.instrument?.symbol)} (Multiplier:{" "}
              {trade.instrument?.multiplier || 1})
            </Text>
          </VStack>

          <FormControl>
            <FormLabel>Buy/Sell</FormLabel>
            <Select
              value={buySell}
              onChange={(e) => setBuySell(e.target.value)}
            >
              <option value="Buy">Buy</option>
              <option value="Sell">Sell</option>
            </Select>
          </FormControl>

          <HStack>
            <FormControl mt={4}>
              <FormLabel>Commission</FormLabel>
              <Input
                type="number"
                step="0.01"
                placeholder="0.00"
                value={commission}
                onChange={(e) =>
                  handleNumericInput(e.target.value, setCommission)
                }
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Fees</FormLabel>
              <Input
                type="number"
                step="0.01"
                placeholder="0.00"
                value={fees}
                onChange={(e) => handleNumericInput(e.target.value, setFees)}
              />
            </FormControl>
          </HStack>

          <HStack>
            <FormControl mt={4}>
              <FormLabel>Price</FormLabel>
              <Input
                type="number"
                step="0.01"
                placeholder="0.00"
                value={price}
                onChange={(e) => handleNumericInput(e.target.value, setPrice)}
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Quantity</FormLabel>
              <Input
                type="number"
                step="1"
                placeholder="0"
                value={quantity}
                onChange={(e) =>
                  handleNumericInput(e.target.value, setQuantity)
                }
              />
            </FormControl>
          </HStack>
          <FormControl mt={4}>
            <FormLabel>Trade Date</FormLabel>
            <Input
              type="date"
              value={moment.utc(tradeDate).format("YYYY-MM-DD")}
              onChange={(e) => setTradeDate(e.target.value)}
            />
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Gross Proceeds</FormLabel>
            <Input
              disabled
              type="number"
              step="0.01"
              placeholder="0.00"
              value={grossProceeds}
              onChange={(e) =>
                handleNumericInput(e.target.value, setGrossProceeds)
              }
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Net Proceeds</FormLabel>
            <Input
              type="number"
              step="0.01"
              disabled
              placeholder="0.00"
              value={netProceeds}
              onChange={(e) =>
                handleNumericInput(e.target.value, setNetProceeds)
              }
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            bg="var(--accent)"
            mr={3}
            onClick={handleConfirm}
            disabled={loading === true}
          >
            {loading === true ? <Spinner /> : "Confirm"}
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditTradeModal;
