import Big from "big.js";
import { calculateReturnOnBasis } from "../../../utils";

export const returnOnBasis = (props: any) => {
  const children = props.rowNode?.allLeafChildren?.map((child: any) => {
    return child.data;
  });
  if (children && children.length > 0) {
    const costBasis = children.reduce((acc: Big, child: any) => {
      return child.costBasis ? acc.plus(child.costBasis) : acc;
    }, new Big(0));

    const realisedPnl = children.reduce((acc: Big, child: any) => {
      return child.realisedPnl ? acc.plus(child.realisedPnl) : acc;
    }, new Big(0));

    const unrealisedPnl = children.reduce((acc: Big, child: any) => {
      return child.unrealisedPnl ? acc.plus(child.unrealisedPnl) : acc;
    }, new Big(0));

    return calculateReturnOnBasis(
      costBasis.toNumber(),
      realisedPnl.toNumber(),
      unrealisedPnl.toNumber()
    );
  }
  return "";
};
