import React from "react";
import { Text } from "@chakra-ui/react";
import moment from "moment";

export const DateColumn = (props: any) => {
  if (!props.value) return null;

  if (
    props.dateOnly === true ||
    moment(props.value, "YYYY-MM-DDThh:mm:ss").toString().includes("00:00:00")
  ) {
    return (
      <Text>{moment(props.value, "YYYY-MM-DD").format("MM/DD/YYYY")}</Text>
    );
  } else {
    return (
      <Text>
        {moment(props.value, "YYYY-MM-DDThh:mm:ss").format("MM/DD/YYYY hh:mm")}
      </Text>
    );
  }
};
