import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Box,
  HStack,
  Wrap,
  ModalCloseButton,
  Heading,
  Divider,
} from "@chakra-ui/react";
import BrokerUpload from "./panels/BrokerUpload/BrokerUpload";
import { useUploadsController } from "./useUploadController";
import { GoBack } from "./components/GoBack";
import { FaFile, FaFlask, FaMoneyBill, FaRocket } from "react-icons/fa";
import styles from "./Uploads.module.css";
import mixpanel from "mixpanel-browser";
import { useTour } from "@reactour/tour";
import { progressTourOneStep } from "../../components/tutorial/util/progressTour";
import React from "react";
import AddEquityForm from "./panels/AddEquity/AddEquityForm";
import KnowledgeBaseHelper from "../knowledgebase/KnowledgeBaseHelper";
import { SecondaryButton } from "../../components/design_library";
import { RiOmega } from "react-icons/ri";
import { TbDelta } from "react-icons/tb";
import AddOptionForm from "./panels/AddOption/AddOptionForm";
import { IoAdd, IoAddCircle, IoCash } from "react-icons/io5";

const Uploads = () => {
  const controller = useUploadsController();

  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } =
    useTour();

  let instruments = [
    // {
    //   title: "Upload trades",
    //   subtitle:
    //     "Upload your assets using your broker (Supports, equities, options and futures)",
    //   icon: <FaFile fontSize={"24px"} />,
    //   onClick: () => {
    //     controller.setDisplayedPanel("BROKER_UPLOAD");
    //   },
    //   flex: 2,
    //   disabled: false,
    // },
    {
      title: "Shares & ETFs",
      subtitle: "Manually enter listed and unlisted equities",
      icon: <FaMoneyBill fontSize={"24px"} />,
      onClick: () => {
        controller.setDisplayedPanel("CREATE_EQUITY");
      },
      flex: 1,
      disabled: false,
    },
    // {
    //   title: "Futures",
    //   subtitle: "Coming soon...",
    //   icon: <StarIcon fontSize={"24px"} />,
    //   disabled: true,
    //   onClick: () => {},
    // },
    // {
    //   title: "Unlisted Equities",
    //   subtitle: "Coming soon...",
    //   icon: <FaMagic fontSize={"24px"} />,
    //   onClick: () => {},
    //   flex: 1,
    //   disabled: true,
    // },
    // {
    //   title: "Cash",
    //   subtitle: "Coming soon...",
    //   icon: <FaCashRegister fontSize={"24px"} />,
    //   onClick: () => {},
    //   flex: 1,
    //   disabled: true,
    // },
    {
      title: "Options",
      subtitle: "Manually add listed and unlisted options",
      icon: <TbDelta fontSize={"24px"} />,
      onClick: () => {
        controller.setDisplayedPanel("CREATE_OPTION");
      },
      flex: 1,
      disabled: false,
    },
    // {
    //   title: "Fixed Income",
    //   subtitle: "Coming soon...",
    //   icon: <FaFileContract fontSize={"24px"} />,
    //   onClick: () => {},
    //   flex: 1,
    //   disabled: true,
    // },
    // {
    //   title: "Crypto",
    //   subtitle: "Coming soon...",
    //   icon: <FaDog fontSize={"24px"} />,
    //   onClick: () => {},
    //   flex: 1,
    //   disabled: true,
    // },
    // {
    //   title: "Real Assets",
    //   subtitle: "Coming soon...",
    //   icon: <FaRing fontSize={"24px"} />,
    //   onClick: () => {},
    //   flex: 2,
    //   disabled: true,
    // },
    // {
    //   title: "Real Estate",
    //   subtitle: "Coming soon...",
    //   icon: <FaHouseUser fontSize={"24px"} />,
    //   onClick: () => {},
    //   flex: 3,
    //   disabled: true,
    // },
  ];

  return (
    <>
      <SecondaryButton
        h="45px"
        onClick={() => {
          controller.setIsOpen(true);
          mixpanel.track("Add Assets Button Clicked");

          progressTourOneStep(setCurrentStep, currentStep, setIsOpen);
          controller.setDisplayedPanel("");
        }}
        className={["navbar-add-assets-hook", "tutorial-add-assets"].join(" ")}
        px={{
          base: "28px",
          md: "16px",
        }}
        fontSize={{
          base: "14px",
          md: "12px",
        }}
        backgroundColor={"var(--accent)"}
      >
        <Text
          display={{
            base: "none",
            lg: "block",
          }}
          className="addInsightsButton tutorial-add-assets"
        >
          <HStack>
            <Text textShadow={"0px 0px 2px var(--dark-gray)"} fontWeight={700}>
              Add assets
            </Text>
            <IoAddCircle
              textShadow={"0px 0px 2px var(--dark-gray)"}
              size="20px"
            />
          </HStack>
        </Text>
        <Text
          ml="2"
          mr="2"
          display={{
            lg: "none",
            base: "block",
          }}
        >
          <IoCash />
        </Text>
      </SecondaryButton>

      <Modal
        isOpen={controller.isOpen}
        onClose={() => {
          controller.setIsOpen(false);
          controller.setDisplayedPanel("");
        }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay backdropFilter="blur(3px)" />
        <ModalContent maxW="3xl" borderRadius={"6px"}>
          <ModalBody
            bg="var(--dark-gray)"
            borderRadius={"6px"}
            pt="26px"
            className="modal-body-tutorial"
          >
            <ModalCloseButton />

            {controller.displayedPanel.length < 1 && (
              <Box mt="10px">
                <HStack justifyContent={"space-between"}>
                  <Heading pl="8px" size="lg">
                    Add assets
                  </Heading>
                  <KnowledgeBaseHelper topic="Importing" />
                </HStack>

                <Box h="20px" />

                <Container
                  className={["tutorial-assets-list"]}
                  //Upload
                  key={"container-0-Upload"}
                  flex={2}
                  disabled={false}
                  icon={<FaFile fontSize={"24px"} />}
                  title={"Upload trades"}
                  subtitle={
                    "Upload your assets using your broker (Supports, equities, options and futures)"
                  }
                  onClick={() => {
                    controller.setDisplayedPanel("BROKER_UPLOAD");

                    progressTourOneStep(setCurrentStep, currentStep, setIsOpen);
                  }}
                />

                <HStack mt="10px" ml="5px">
                  <Text
                    minW="90px"
                    h="50px"
                    display="flex"
                    fontSize={"sm"}
                    alignItems={"center"}
                  >
                    Manual Entry
                  </Text>
                  <Divider mt="15px" mb="10px" />
                </HStack>

                <Wrap
                  // flexWrap={"wrap"}
                  maxW={"4xl"}
                  pb="10px"
                  pt="16px"
                >
                  <>
                    {instruments.map((instrument, i) => {
                      return (
                        <Container
                          className={["tutorial-assets-list"]}
                          key={"container-" + i + "-" + instrument.title}
                          flex={instrument.flex}
                          disabled={instrument.disabled}
                          icon={instrument.icon}
                          title={instrument.title}
                          subtitle={instrument.subtitle}
                          onClick={() => {
                            progressTourOneStep(
                              setCurrentStep,
                              currentStep,
                              setIsOpen
                            );
                            instrument.onClick();
                          }}
                        />
                      );
                    })}
                  </>
                </Wrap>
              </Box>
            )}

            {controller.displayedPanel === "BROKER_UPLOAD" && (
              <BrokerUpload
                closeModal={() => controller.setIsOpen(false)}
                goBackButton={
                  <GoBack onClick={() => controller.setDisplayedPanel("")}>
                    Go back to instrument selection
                  </GoBack>
                }
              />
            )}

            {controller.displayedPanel === "CREATE_EQUITY" && (
              <AddEquityForm
                onComplete={() => controller.setIsOpen(false)}
                goBackButton={
                  <GoBack onClick={() => controller.setDisplayedPanel("")}>
                    Go back to instrument selection
                  </GoBack>
                }
              />
            )}

            {controller.displayedPanel === "CREATE_OPTION" && (
              <AddOptionForm
                onComplete={() => controller.setIsOpen(false)}
                goBackButton={
                  <GoBack onClick={() => controller.setDisplayedPanel("")}>
                    Go back to instrument selection
                  </GoBack>
                }
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

interface ContainerProps {
  title: string;
  subtitle: string;
  icon: JSX.Element; // Assuming icon is a JSX element (e.g., from react-icons)
  onClick: () => void;
  flex: number;
  disabled: boolean;
  key?: string;
  className?: string[];
}

const Container: React.FC<ContainerProps> = ({
  title,
  subtitle,
  icon,
  onClick,
  flex,
  disabled,
  key,
  className,
}) => {
  return (
    <Box
      key={"c-" + key}
      className={[
        !disabled ? styles.container : styles.disabledContainer,
        ...className,
      ].join(" ")}
      border="solid 1px var(--gray)"
      borderRadius={"6px"}
      textAlign={"center"}
      minW={"260px"}
      padding="10px"
      cursor={!disabled ? "pointer" : "now-allowed"}
      height="100px"
      margin="4px"
      alignItems={"center"}
      pl="30px"
      justifyContent={"flex-start"}
      display="flex"
      bg={disabled ? "var(--dark-gray)" : "var(--bg)"}
      color={disabled ? "var(--light-gray)" : "var(--white)"}
      flex={flex}
      onClick={() => onClick()}
    >
      <Box>
        <HStack alignItems={"flex-start"} justifyContent={"flex-start"}>
          {icon}
          <Text>{title}</Text>
        </HStack>
        <Text fontSize={"xs"} pt="4px" textAlign={"left"}>
          {subtitle}
        </Text>
      </Box>
    </Box>
  );
};

export default Uploads;
