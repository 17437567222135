import { useState } from "react";
import { useAtomValue } from "jotai";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  CustomInputGroup,
  PrimaryButton,
  TextInput,
} from "../../../components/design_library";
import {
  HeaderContainer,
  InnerContainer,
  Meta,
  OuterContainer,
} from "../../../components";
import { useRegisterController } from "./useRegisterController";
import { useNavigate } from "react-router";
import { AuthenticationFrame } from "../components/AuthenticationFrame";

/* eslint-disable @typescript-eslint/no-unused-vars */
export const Register = (props: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const controller = useRegisterController();
  const navigate = useNavigate();

  return (
    <div>
      <Meta
        title={"WealthBee Trading Journal - Register"}
        description={"Register an account with WealthBee"}
        canonical={"https://wealthbee.io/app/register"}
      />

      <AuthenticationFrame>
        <HeaderContainer>
          <Heading mb="4">Register</Heading>
        </HeaderContainer>
        <InnerContainer>
          <FormControl isInvalid={controller.errorMessage ? true : false}>
            <Stack spacing={5}>
              <FormControl
                id="email"
                isRequired
                isInvalid={controller.formEmailError ? true : false}
              >
                <FormLabel>Email</FormLabel>
                <CustomInputGroup>
                  <TextInput
                    isRequired
                    bg={"var(--bg)"}
                    type={"text"}
                    placeholder="Enter email"
                    disabled={controller.loading}
                    onChange={(e: any) => {
                      controller.setEmail(e.target.value);
                    }}
                    autoComplete="email"
                    onKeyPress={(e: any) => {
                      if (e.key === "Enter") {
                        controller.register();
                      }
                    }}
                  />
                  <FormErrorMessage>
                    {controller.formEmailError}
                  </FormErrorMessage>
                </CustomInputGroup>
              </FormControl>

              <FormControl
                id="password"
                isRequired
                isInvalid={controller.formPasswordError ? true : false}
              >
                <FormLabel>Password</FormLabel>
                <CustomInputGroup>
                  <TextInput
                    bg={"var(--bg)"}
                    placeholder="Enter password"
                    autoComplete="password"
                    type={showPassword ? "text" : "password"}
                    autocomplete="password"
                    disabled={controller.loading}
                    onChange={(e: any) => {
                      controller.setPassword(e.target.value);
                    }}
                    toggleShow={true}
                    toggleShowContents={showPassword ? "Hide" : "Show"}
                    toggleShowFunc={() => setShowPassword(!showPassword)}
                    onKeyPress={(e: any) => {
                      if (e.key === "Enter") {
                        controller.register();
                      }
                    }}
                  />
                  <FormErrorMessage>
                    {controller.formPasswordError}
                  </FormErrorMessage>
                </CustomInputGroup>
                <FormHelperText>
                  Must be between 6 and 100 characters
                </FormHelperText>
              </FormControl>

              <FormControl id="termsAndConditions" isRequired>
                <CustomInputGroup>
                  <Text size="lg" id="termsCheck">
                    {" "}
                    By continuing you agree to the{" "}
                    <Link
                      target="_blank"
                      href={"https://wealthbee.io/terms"}
                      color={"var(--accent)"}
                    >
                      {" "}
                      terms of service{" "}
                    </Link>{" "}
                    and{" "}
                    <Link
                      target="_blank"
                      href={"https://wealthbee.io/privacy"}
                      color={"var(--accent)"}
                    >
                      {" "}
                      privacy policy.
                    </Link>
                  </Text>
                </CustomInputGroup>
              </FormControl>

              <PrimaryButton
                isLoading={controller.loading}
                colorScheme="blue"
                variant="solid"
                onClick={() => controller.register()}
                isFullWidth
                loadingText="Creating your account..."
                color="var(--white)"
                id="createaccount"
              >
                Continue
              </PrimaryButton>
              <FormErrorMessage>{controller.errorMessage}</FormErrorMessage>

              <Text mt="4" align="center" maxW="md" fontWeight="medium">
                Already have an account?
                <Box
                  as="a"
                  href="#"
                  marginStart="2"
                  textDecor={"underline"}
                  onClick={() => {
                    navigate("/app/login");
                  }}
                  color={"var(--accent)"}
                >
                  Sign in
                </Box>
              </Text>
            </Stack>
          </FormControl>
        </InnerContainer>
      </AuthenticationFrame>
    </div>
  );
};
