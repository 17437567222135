import React from "react";
import { ColDef } from "ag-grid-community";
import { ColumnTypeEnum, createGroupColumn } from "../../../components";
import convertSymbolToReadable from "../../../components/grid/utils/convertSymbolToReadable";
import Notes from "../../notes/Notes";

export const tradeColumnDefinitions: ColDef[] = [
  createGroupColumn("Id", "brokerTradeId", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
  }),
  createGroupColumn("Notes", "notes", ColumnTypeEnum.Text, {
    maxWidth: 65,
    aggFunc: "distinct",
    cellRenderer: (params: any) => {
      if (params.data?.notes || params.data?.tags) {
        return (
          <Notes
            notes={params.data?.notes}
            tags={params.data?.tags[0] !== "" ? params.data?.tags : null}
          />
        );
      } else {
        return null;
      }
    },
  }),

  createGroupColumn("Date", "tradeDate", ColumnTypeEnum.Date, {
    rowGroup: true,
    aggFunc: "distinct",
  }),
  createGroupColumn(
    "Symbol",
    "underlyingInstrument.symbol",
    ColumnTypeEnum.Text,
    {
      valueGetter: (params) => {
        return convertSymbolToReadable(params?.data?.instrument?.symbol);
      },
      aggFunc: "distinct",
    }
  ),

  createGroupColumn(
    "Underlying Symbol",
    "underlyingInstrument.symbol",
    ColumnTypeEnum.Text,
    {
      valueGetter: (params) => {
        if (params.data?.underlyingInstrument?.instrumentType === "Future") {
          return params.data.underlyingInstrument.name;
        } else {
          return params?.data?.underlyingInstrument?.symbol;
        }
      },
      aggFunc: "distinct",
    }
  ),

  createGroupColumn("Position Group", "positionGroup", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
  }),
  createGroupColumn("Book", "book", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
  }),

  createGroupColumn("Strategy", "strategy", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
  }),

  createGroupColumn("Type", "tradeType", ColumnTypeEnum.Pill, {
    cellRendererParams: {
      exactMatch: true,
      colourMap: {
        Option: "var(--accent)",
        Equity: "var(--trade-stock)",
      },
      defaultColour: "var(--accent)",
    },
    aggFunc: "distinct",
  }),
  createGroupColumn("Buy/Sell", "buySell", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
  }),
  createGroupColumn("Put/Call", "instrument.putCall", ColumnTypeEnum.Pill, {
    cellRendererParams: {
      exactMatch: true,
      colourMap: {
        Put: "var(--trade-call)",
        Call: "var(--trade-put)",
      },
      defaultColour: "var(--accent)",
    },
    aggFunc: "distinct",
  }),
  createGroupColumn("Strike", "instrument.strike", ColumnTypeEnum.Numeric, {
    aggFunc: "distinct",
  }),
  createGroupColumn("Expiry Date", "instrument.expiry", ColumnTypeEnum.Date, {
    aggFunc: "distinct",
  }),
  createGroupColumn("Quantity", "quantity", ColumnTypeEnum.Numeric, {
    aggFunc: "none",
  }),

  createGroupColumn("Trade price", "price", ColumnTypeEnum.Currency, {
    aggFunc: "none",
    cellRendererParams: {
      noFormatting: true,
    },
  }),
  createGroupColumn(
    "Market price",
    "priceData.price",

    ColumnTypeEnum.PriceDetail,
    {
      aggFunc: "none",
    }
  ),
  createGroupColumn(
    "Gross proceeds",
    "grossProceeds",
    ColumnTypeEnum.Currency,
    {
      aggFunc: "sum",
      cellRendererParams: { showDirection: true },
      valueGetter: (params) => {
        return parseFloat(params.data?.grossProceeds);
      },
    }
  ),
  createGroupColumn("Net proceeds", "netProceeds", ColumnTypeEnum.Currency, {
    aggFunc: "sum",
    valueGetter: (params) => {
      return parseFloat(params.data?.netProceeds);
    },
    cellRendererParams: { showDirection: true },
  }),
  createGroupColumn("Fees", "fees", ColumnTypeEnum.Currency, {
    aggFunc: "sum",
    valueGetter: (params) => {
      const fees = parseFloat(params.data?.fees) || 0;
      const commission = parseFloat(params.data?.commission) || 0;
      return -Math.abs(fees + commission);
    },
  }),

  createGroupColumn("Delta", "priceData.greeks.delta", ColumnTypeEnum.Numeric),
  createGroupColumn("Gamma", "priceData.greeks.gamma", ColumnTypeEnum.Numeric),
  createGroupColumn("IV", "priceData.greeks.iv", ColumnTypeEnum.Numeric),
  createGroupColumn("Phi", "priceData.greeks.phi", ColumnTypeEnum.Numeric),
  createGroupColumn("Rho", "priceData.greeks.rho", ColumnTypeEnum.Numeric),
  createGroupColumn("Theta", "priceData.greeks.theta", ColumnTypeEnum.Numeric),
  createGroupColumn("Vega", "priceData.greeks.vega", ColumnTypeEnum.Numeric),
];
