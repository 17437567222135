import { useAtom, useAtomValue } from "jotai";
import { FilterStateAtom } from "../store/FilterStateAtom";
import { PositionGroupingOptions, PositionStatus } from "../types";

export interface UseFilters {
  setSelectedUnderlying: (underlying: string) => void;
  setUnderlyings: (underlyings: string[]) => void;
  underlyings: string[];
  setPositionStatus: (status: string) => void;
  positionStatus: PositionStatus;
  setSelectedTag: (underlying: string) => void;
  tags: string[];
  selectedTag: string;
  setTags: (tags: string[]) => void;
  selectedUnderlying: string;
  toDate: Date;
  fromDate: Date;
  setToDate: (date: Date) => void;
  setFromDate: (date: Date) => void;
  positionGroups: string[];
  setPositionGroups: (groups: string[]) => void;
  books: string[];
  setBooks: (books: string[]) => void;
  setSelectedPositionGroup: (group: string) => void;
  setSelectedBook: (book: string) => void;
  selectedPositionGroup: string;
  selectedBook: string;
  selectedStrategy: string;
  setSelectedStrategy: (strategy: string) => void;
  baseStrategies: string[];
  tradedStrategies: string[];
  setBaseStrategies: (strategies: string[]) => void;
  setTradedStrategies: (strategies: string[]) => void;
  clear: () => void;
}

export const useFilters = (): UseFilters => {
  const [filters, setFilters] = useAtom(FilterStateAtom);
  const setSelectedUnderlying = async (underlying: string) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        selectedUnderlying: underlying,
      };
    });
  };

  const setFromDate = async (date: Date) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        fromDate: date,
      };
    });
  };

  const setBooks = async (books: string[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        books: ["All", ...books],
      };
    });
  };

  const setToDate = async (date: Date) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        toDate: date,
      };
    });
  };

  const setUnderlyings = async (underlyings: string[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        underlyings: ["All", ...underlyings],
      };
    });
  };

  const setPositionStatus = async (status: string) => {
    localStorage.setItem("positionStatus", status);

    setFilters((prevState) => {
      return {
        ...prevState,
        status: status,
      };
    });
  };

  const setPositionGroups = async (groups: string[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        positionGroups: ["All", ...groups],
      };
    });
  };

  const setSelectedPositionGroup = async (group: string) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        selectedPositionGroup: group,
      };
    });
  };

  const setSelectedBook = async (book: string) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        selectedBook: book,
      };
    });
  };

  const setSelectedTag = async (tag: string) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        selectedTag: tag,
      };
    });
  };

  const setTags = async (tags: string[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        tags: [...tags],
      };
    });
  };

  const setSelectedStrategy = async (strategy: string) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        selectedStrategy: strategy,
      };
    });
  };

  const setBaseStrategies = async (strategies: string[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        baseStrategies: ["All", ...strategies],
      };
    });
  };

  const setTradedStrategies = async (strategies: string[]) => {
    setFilters((prevState) => {
      return {
        ...prevState,
        tradedStrategies: ["All", ...strategies],
      };
    });
  };

  const clear = () => {
    setFilters((prevState) => {
      return {
        ...prevState,
        selectedUnderlying: undefined,
        selectedPositionGroup: "All",
        selectedBook: "All",
        selectedTag: "All",
        selectedStrategy: "All",
        status: "all",
      };
    });
  };

  return {
    selectedPositionGroup: filters.selectedPositionGroup,
    setSelectedPositionGroup,
    selectedBook: filters.selectedBook,
    setSelectedBook,
    setBooks,
    books: filters.books,
    setPositionGroups,
    positionGroups: filters.positionGroups,
    setSelectedTag,
    setTags,
    tags: filters.tags,
    setSelectedUnderlying,
    setUnderlyings,
    setPositionStatus,
    selectedTag: filters.selectedTag,
    positionStatus: filters.status,
    underlyings: filters.underlyings,
    selectedUnderlying: filters.selectedUnderlying,
    toDate: filters.toDate,
    fromDate: filters.fromDate,
    setToDate,
    setFromDate,
    selectedStrategy: filters.selectedStrategy,
    setSelectedStrategy,
    baseStrategies: filters.baseStrategies,
    tradedStrategies: filters.tradedStrategies,

    setBaseStrategies,
    setTradedStrategies,
    clear,
  };
};
