import React, { useState } from "react";
import {
  Box,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Select,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";
import { SecondaryButton } from "../../components/design_library";

const CancelSubscription = () => {
  const [reason, setReason] = useState("");
  const [feedback, setFeedback] = useState("");
  const toast = useToast();

  const handleCancelRequest = async () => {
    // Validate the form
    if (!reason) {
      toast({
        title: "Reason required",
        description: "Please select a reason for cancellation.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!feedback || feedback.length < 30) {
      toast({
        title: "Feedback required",
        description:
          "Please provide additional feedback with at least 30 characters.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Crisp API integration
    try {
      window.$crisp.push([
        "do",
        "message:send",
        [
          "text",
          `Subscription Cancellation Request: 
        Reason: ${reason} 
        Additional Feedback: ${feedback}`,
        ],
      ]);

      toast({
        title: "Request sent",
        description:
          "Your cancellation request has been sent. We'll be in touch shortly.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Reset form fields
      setReason("");
      setFeedback("");
    } catch (error) {
      toast({
        title: "Error",
        description:
          "An error occurred while sending your request. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxWidth="500px" mx="auto" borderRadius="md">
      <Heading size="md" mb="4">
        Request to Cancel Your Subscription
      </Heading>
      <Text mb="4">
        We’re sorry to see you go! Please let us know why you’re canceling so we
        can improve our services.
      </Text>

      <FormControl id="reason" mb="4" isRequired>
        <FormLabel>Reason for Cancellation</FormLabel>
        <Select
          placeholder="Select a reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        >
          <option value="Pricing too high">Pricing too high</option>
          <option value="Features not useful">Features not useful</option>
          <option value="Switching to another service">
            Switching to another service
          </option>
          <option value="Other">Other</option>
        </Select>
      </FormControl>

      <FormControl id="feedback" mb="4" isRequired>
        <FormLabel>Additional Feedback (min. 30 characters)</FormLabel>
        <Textarea
          placeholder="Provide any additional feedback here (required)"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
      </FormControl>

      <SecondaryButton w="full" onClick={handleCancelRequest} isFullWidth>
        Submit Cancellation Request
      </SecondaryButton>
    </Box>
  );
};

export default CancelSubscription;
