import { ReactElement } from "react";
import { LicenseManager } from "ag-grid-enterprise";
import { Box } from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { AppStateAtom } from "../../store";
import React from "react";

LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-042988 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Option Tracker Ltd )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Option Tracker )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Option Tracker )_need_to_be_licensed___( Option Tracker )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 26 June 2024 )____[v2]_MTcxOTM1NjQwMDAwMA==da26b5a51856f363e61b37e5775314c6"
);

export type GridContainerProps = {
  children: ReactElement;
  customClass?: string;
  style?: any;
};

export const GridContainer = (props: GridContainerProps) => {
  const appState = useAtomValue(AppStateAtom);

  let theme = appState.gridTheme || "ag-theme-alpine";

  return (
    <Box
      w="full"
      className={`${theme} table ${props.customClass}`}
      minH="100px"
      minW="100px"
      height="full"
      p="2px"
      mb="50px"
      style={props.style ? { ...(props.style as any) } : {}}
    >
      {props.children}
    </Box>
  );
};
