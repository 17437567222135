import {
  Box,
  Center,
  HStack,
  ModalOverlay,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "../../components/calendar";
import "./Journal.css";
import { CreateCalendarNote, GetCalendarNotes } from "../../api/CalendarNotes";
import { useAtomValue } from "jotai";
import { AccountAtom, AppStateAtom } from "../../store";
import { NotesModal } from "../notes/Notes";
import { CalendarNotesModal } from "../../components/calendar/CalendarNoteModal";
import { useInstruments, usePositions } from "../../hooks";
import convertSymbolToReadable from "../../components/grid/utils/convertSymbolToReadable";
const localizer = momentLocalizer(moment);

const CalendarComponent: React.FC = () => {
  const [events, setEvents] = useState<any[]>([]);

  const [month, setMonth] = useState(moment.utc().format("MMM YYYY"));
  const [day, setDay] = useState(moment.utc().format("DD MMM YYYY"));
  const [loading, setLoading] = useState(true);
  const [notes, setNotes] = useState<any[]>([]);
  const [selectedNote, setSelectedNote] = useState<any>(null);
  const [expiries, setExpiries] = useState<any[]>([]);
  const instruments = useInstruments();

  const [isOpen, setIsOpen] = useState(false);

  const accountState = useAtomValue(AccountAtom);
  const [expiriesForThisDate, setExpiriesForThisDate] = useState<any[]>([]);
  const [pnlByDate, setPnlByDate] = useState<any[]>([]);
  const [trades, setTrades] = useState<any[]>([]);
  const [tradesForThisDate, setTradesForThisDate] = useState<any[]>([]);
  const [pnlThisMonth, setPnlThisMonth] = useState<any[]>([]);
  const [numberOfTradedDays, setNumberOfTradedDays] = useState<number>(0);

  useEffect(() => {
    getNotes(month);
  }, [accountState.selectedAccount.id]);

  const getNotes = async (month: string) => {
    if (!accountState.selectedAccount) return;
    if (!accountState.selectedAccount.id) return;
    if (!month) return;
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 5000);

    const notes = await GetCalendarNotes(
      month,
      accountState.selectedAccount.id
    );

    const expiriesEventArray = notes.expiries.map(
      (expiry: any, index: number) => {
        return {
          id: expiry.symbol + "-" + index,
          title: "Expiry: " + convertSymbolToReadable(expiry.symbol),
          start: moment
            .utc(expiry.expiry)
            .endOf("day")
            .subtract(6, "hour")
            .toDate(),
          end: moment.utc(expiry.expiry).endOf("day").toDate(),
          allDay: false,
          resourceId: expiry.symbol + "-" + index,
        };
      }
    );

    let tradesEventArray = notes.trades.map((trade: any, index: number) => {
      const ins = instruments.instruments.find(
        (x) => x.id === trade.instrumentId
      );
      if (!ins) return;
      return {
        id: ins.symbol,
        title: "Trade: " + convertSymbolToReadable(ins.symbol),
        start: moment.utc(trade.tradeDate).toDate(),
        end: moment.utc(trade.tradeDate).add(3, "hour").toDate(),
        allDay: false,
        resourceId: ins.symbol + "-" + index,
      };
    });
    //merge tradesEvents where they have same ID;
    tradesEventArray = tradesEventArray.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (x) {
        return acc;
      } else {
        return acc.concat([current]);
      }
    }, []);

    setEvents([...expiriesEventArray, ...tradesEventArray]);
    setNotes(notes.notes || []);
    setExpiries(notes.expiries || []);
    setPnlByDate(notes.pnl || []);
    setNumberOfTradedDays(notes.numberOfTradedDays || 0);
    setPnlThisMonth(notes.totalPNL || []);
    setTrades(notes.trades || []);
    setLoading(false);
  };

  const createNote = async (content: string, date: string) => {
    setLoading(true);
    setExpiriesForThisDate(
      expiries.filter((expiry) => {
        return (
          moment.utc(expiry.expiry, "YYYY-MM-DD").format("YYYY-MM-DD") === date
        );
      })
    );
    setTradesForThisDate(
      trades.filter((trade) => {
        return moment.utc(trade.tradeDate).format("YYYY-MM-DD") === date;
      })
    );

    const existingNote = notes.find((note) => {
      return (
        moment(note.calendarDateReference).format("YYYY-MM-DD") ===
        moment(date).format("YYYY-MM-DD")
      );
    });
    if (!existingNote) {
      const note = await CreateCalendarNote(
        date,
        content,
        accountState.selectedAccount.id
      );

      setSelectedNote(note.note);
      setIsOpen(true);

      getNotes(month);
    } else {
      setSelectedNote(existingNote);
      setIsOpen(true);
    }
    setLoading(false);
  };

  return (
    <Box p="20px">
      {loading && (
        <Box
          pos="fixed"
          bg="var(--bg)"
          w="100vw"
          h="100vh"
          top="0"
          left="0"
          zIndex={99}
          opacity="0.2"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Spinner opacity="1" size="xl" />
        </Box>
      )}
      <CalendarNotesModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          getNotes(month);
        }}
        note={selectedNote}
        date={selectedNote?.calendarDateReference}
        expiries={expiriesForThisDate}
        trades={tradesForThisDate}
      />

      <Calendar
        setDay={(d) => {
          createNote("", moment(d).format("YYYY-MM-DD"));
        }}
        day={day}
        localizer={localizer}
        events={events}
        notes={notes}
        expiries={expiries}
        pnlByDate={pnlByDate}
        trades={trades}
        numberOfTradedDays={numberOfTradedDays}
        pnlThisMonth={pnlThisMonth}
        startAccessor="start"
        endAccessor="end"
        style={{ minHeight: "85vh", height: "100%", width: "100%" }}
        onNavigate={(date: Date) => {
          setMonth(moment.utc(date).format("MMM YYYY"));
          getNotes(moment.utc(date).format("MMM YYYY"));
        }}
        dailyPandL={[]}
      />
    </Box>
  );
};

export default CalendarComponent;
