import { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import { Box, HStack, Spinner, Text, VStack } from "@chakra-ui/react";
import { Position, Trade } from "../../types";
import React from "react";

const calculatePayoff = (underlyingPrice, leg) => {
  const { strike, premium, action, type, quantity } = leg;
  const position = action === "Buy" ? quantity : -quantity;

  if (type === "CE") {
    return position * (Math.max(underlyingPrice - strike, 0) - premium);
  } else {
    return position * (Math.max(strike - underlyingPrice, 0) - premium);
  }
};

const roundToNearestPowerOfTen = (number) => {
  const powerOfTen = Math.pow(1, Math.floor(Math.log1p(number)));
  return Math.round(number / powerOfTen) * powerOfTen;
};

const generatePayoffDiagram = (legs) => {
  if (legs.length === 0) return [{ x: 0, y: 0 }];

  const strikes = legs.map((leg) => leg.strike);
  const minStrike = Math.min(...strikes);
  const maxStrike = Math.max(...strikes);
  const avgStrike = (maxStrike + minStrike) / 2;
  const startStrike = Math.max(minStrike - avgStrike / 2, 0);
  const endStrike = maxStrike + avgStrike / 2;
  const data = [];

  for (let i = startStrike; i < endStrike; ) {
    const underlyingPrice = i;
    const totalPayoff = legs.reduce(
      (sum, leg) => sum + calculatePayoff(underlyingPrice, leg),
      0
    );
    data.push({ x: underlyingPrice, y: totalPayoff });
    i += roundToNearestPowerOfTen(endStrike) / 400;
  }
  return data;
};

interface PayoffChartProps {
  trades?: Trade[];
  underlyingPrice: number;
  positions: Position[];
}

const PayoffChart = (props: PayoffChartProps) => {
  const chartParentRef = useRef();
  const chartAreaRef = useRef();
  const xTooltipRef = useRef();
  const statsTooltipRef = useRef();

  const yTooltipRef = useRef();
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [multiplier, setMultiplier] = useState(100);
  const [loading, setLoading] = useState(true);
  const [selectedStrategyLegs, setSelectedStrategyLegs] = useState<Trade[]>([]);
  const [hasOptions, setHasOptions] = useState(false);

  useEffect(() => {
    setLoading(true);

    let data: any[] = [];

    // Filter the trades, create a unique ID from the instrument details, and remove duplicates
    props.positions
      .filter((x) => x.quantity !== 0)
      .forEach((position: Position) => {
        if (position.instrument?.instrumentType === "Option") {
          setHasOptions(true);
          setMultiplier(position.instrument?.multiplier);
          const id = `${position.instrument?.strike}-${
            position.instrument?.putCall
          }-${position.quantity > 0 ? "Buy" : "Sell"}-${position.id}`;

          // Add to data array if the ID doesn't already exist
          if (!data.some((item) => item.id === id)) {
            data.push({
              id, // Unique ID based on instrument details
              type: position.instrument?.putCall === "Put" ? "PE" : "CE",
              action: position.quantity > 0 ? "Buy" : "Sell",
              strike: position.instrument?.strike,
              quantity: Math.abs(position.quantity),
              premium: position.price,
              selected: true,
              multiplier: position.instrument?.multiplier,
            });
          }
        }
      });

    setSelectedStrategyLegs(data); // Set unique strategy legs

    setLoading(false);
  }, [props.trades, props.underlyingPrice]);

  const drawChart = () => {
    while (chartAreaRef.current.firstChild) {
      chartAreaRef.current.removeChild(chartAreaRef.current.firstChild);
    }

    const MARGIN = { TOP: 50, RIGHT: 70, BOTTOM: 50, LEFT: 100 };
    const WIDTH = width - MARGIN.LEFT - MARGIN.RIGHT;
    const HEIGHT = height - MARGIN.TOP - MARGIN.BOTTOM;

    const selectedLegs = selectedStrategyLegs
      .filter((leg) => leg.selected)
      .sort((a, b) => a.strike - b.strike);
    const data = generatePayoffDiagram(selectedLegs);

    const svg = d3
      .select(chartAreaRef.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`);
    const defs = svg.append("defs");
    // Define gradient for positive area
    defs
      .append("linearGradient")
      .attr("id", "positive-gradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%")
      .selectAll("stop")
      .data([
        { offset: "0%", color: "rgba(0, 255, 0, 1)" },
        { offset: "80%", color: "rgba(0, 255, 0, 0.1)" },
      ])
      .enter()
      .append("stop")
      .attr("offset", (d) => d.offset)
      .attr("stop-color", (d) => d.color);

    // Define gradient for negative area
    defs
      .append("linearGradient")
      .attr("id", "negative-gradient")
      .attr("x1", "0%")
      .attr("y1", "0%")
      .attr("x2", "0%")
      .attr("y2", "100%")
      .selectAll("stop")
      .data([
        { offset: "0%", color: "rgba(255, 0, 0, .05)" },
        { offset: "80%", color: "rgba(255, 0, 0, 1)" },
      ])
      .enter()
      .append("stop")
      .attr("offset", (d) => d.offset)
      .attr("stop-color", (d) => d.color);

    const xScale = d3
      .scaleLinear()
      .domain(d3.extent(data, (d) => d.x))
      .range([0, WIDTH]);

    // const Ymin = d3.min(data, (d) => d.y);
    // const Ymax = d3.max(data, (d) => d.y);
    // const avgY = (Ymax + Ymin) / 2;

    // const yScale = d3
    //   .scaleLinear()
    //   .domain([Ymin - avgY, Ymax + avgY])
    //   .range([HEIGHT, 0]);

    const yScale = d3
      .scaleLinear()
      .domain([d3.min(data, (d) => d.y) * 1, d3.max(data, (d) => d.y) * 1])
      .range([HEIGHT, 0]);

    const xZeroLine = d3.scaleLinear().domain(0).range([0, WIDTH]);

    // Add vertical lines for each strike price
    selectedLegs.forEach((leg) => {
      svg
        .append("line")
        .attr("x1", xScale(leg.strike)) // Use the strike price for x position
        .attr("x2", xScale(leg.strike))
        .attr("y1", 0)
        .attr("y2", HEIGHT)
        .attr("stroke", "var(--chart-blue)") // Customize color of the line
        .attr("stroke-width", 1)
        .attr("stroke-dasharray", "4 2"); // Optional: dashed line for better visibility
    });

    selectedLegs.forEach((leg) => {
      svg
        .append("text")
        .attr("x", xScale(leg.strike))
        .attr("y", HEIGHT / 2)
        .attr("text-anchor", "middle")
        .attr("fill", "var(--chart-blue)")
        .attr("font-size", "12px")
        .attr(
          "transform",
          `rotate(90, ${xScale(leg.strike) + 10}, ${HEIGHT / 2})`
        )
        .text(`Strike: $${leg.strike}`);
    });

    // Add the right y-axis
    svg
      .append("g")
      .attr("class", "y-axis-right")
      .attr("transform", `translate(${WIDTH}, 0)`) // Move to the right side of the chart
      .call(
        d3
          .axisRight(yScale)
          .ticks(10)
          .tickFormat((d) => `$${d3.format(",.0f")(d)}`)
      ); // Right y-axis

    const makeYGridlines = () => d3.axisLeft(yScale).ticks(5);
    const makeXGridlines = () => d3.axisBottom(xScale).ticks(5);

    svg
      .append("g")
      .attr("class", "grid")
      .attr("stroke", "var(--dark-gray)")
      .attr("stroke-width", "0.004rem")
      .attr("transform", `translate(0, ${HEIGHT})`)
      .call(
        makeXGridlines()
          .tickSize(-HEIGHT) // Extend grid lines across the chart's width
          .tickFormat("") // No text for grid lines
      );
    svg
      .append("g")
      .attr("class", "grid")
      .attr("stroke", "var(--dark-gray)")
      .attr("stroke-width", "0.004rem")
      .call(
        makeYGridlines()
          .tickSize(-WIDTH) // Extend grid lines across the chart's width
          .tickFormat("") // No text for grid lines
      );

    const positiveLine = d3
      .line()
      .x((d) => xScale(d.x))
      .y((d) => Math.max(yScale(d.y), 0))
      .defined((d) => d.y >= 0);

    if (
      props.underlyingPrice > xScale.domain()[0] &&
      props.underlyingPrice < xScale.domain()[1]
    ) {
      svg
        .append("line")
        .attr("x1", xScale(props.underlyingPrice))
        .attr("x2", xScale(props.underlyingPrice))
        .attr("y1", 0)
        .attr("y2", HEIGHT)
        .attr("stroke", "var(--red)")
        .attr("stroke-width", 2)
        .attr("stroke-dasharray", "4 2"); // Optional: dashed line for styling

      // Vertical text next to the underlying price line
      svg
        .append("text")
        .attr("x", xScale(props.underlyingPrice) - 10) // Position it slightly left of the line
        .attr("y", HEIGHT / 2) // Center it vertically in the chart
        .attr("text-anchor", "middle")
        .attr("fill", "var(--red)")
        .attr("font-size", "12px")
        .attr(
          "transform",
          `rotate(90, ${xScale(props.underlyingPrice) + 10}, ${HEIGHT / 2})`
        ) // Rotate vertically around the center
        .text(`Underlying Market Price ($${props.underlyingPrice})`);
    }

    svg
      .append("path")
      .datum(data)
      .attr("d", positiveLine)
      .attr("stroke", "var(--green)")
      .attr("stroke-width", 2)
      .attr("fill", "none");

    const insideArea = d3
      .area()
      .x((d) => xScale(d.x))
      .y0(
        yScale(
          Math.max(
            d3.min(data, (d) => d.y),
            0
          )
        )
      )
      .y1((d) => Math.max(yScale(d.y), 0))
      .defined((d) => d.y >= 0);

    const negativeLine = d3
      .line()
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y))
      .defined((d) => d.y <= 0);

    svg
      .append("path")
      .datum(data)
      .attr("d", negativeLine)
      .attr("stroke", "var(--red)")
      .attr("stroke-width", 2)
      .attr("fill", "none");

    const outsideArea = d3
      .area()
      .x((d) => xScale(d.x))
      .y0(
        yScale(
          Math.min(
            d3.max(data, (d) => d.y),
            0
          )
        )
      )
      .y1((d) => yScale(d.y))
      .defined((d) => d.y <= 0);

    // Positive payoff area
    svg
      .append("path")
      .datum(data)
      .attr("d", insideArea)
      .attr("fill", "url(#positive-gradient)")
      .attr("stroke", "none");

    // Negative payoff area
    svg
      .append("path")
      .datum(data)
      .attr("d", outsideArea)
      .attr("fill", "url(#negative-gradient)")
      .attr("stroke", "none");

    const maxTicks = Math.floor(WIDTH / 70);

    svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0, ${HEIGHT})`)
      .call(d3.axisBottom(xScale).ticks(maxTicks).tickFormat(d3.format("~s")));

    svg
      .append("g")
      .attr("class", "y-axis")
      .call(
        d3
          .axisLeft(yScale)
          .ticks(10)
          .tickFormat((d) => `$${d3.format(",.0f")(d * multiplier)}`) // Multiply by multiplier and format as dollars
      );
    svg
      .append("g")
      .attr("class", "x-axis-zero")
      .attr(
        "transform",
        `translate(0, ${Math.min(
          yScale(
            Math.min(
              d3.max(data, (d) => d.y),
              0
            )
          ),
          HEIGHT
        )})`
      )
      // .attr("stroke-dasharray", "12 12")
      .attr("stroke-width", "2px")
      .call(d3.axisBottom(xZeroLine));

    const drawBreakEvenLine = (breakEven) => {
      breakEven = Math.round(breakEven);
      const xPosition = xScale(breakEven);
      const yMin = d3.min(data, (d) => d.y);
      const yMax = d3.max(data, (d) => d.y);

      svg
        .append("line")
        .attr("x1", xPosition)
        .attr("y1", yScale(yMin))
        .attr("x2", xPosition)
        .attr("y2", yScale(yMax))
        .attr("stroke", "var(--light-gray)")
        .attr("stroke-width", 1)
        .attr("stroke-dasharray", "12 12");
    };

    for (let i = 1; i < data.length; i++) {
      const currItem = data[i];
      const prevItem = data[i - 1];
      if (
        (currItem.y < 0 && prevItem.y >= 0) ||
        (currItem.y > 0 && prevItem.y <= 0)
      ) {
        drawBreakEvenLine(
          Math.abs(currItem.y) <= Math.abs(prevItem.y) ? currItem.x : prevItem.x
        );
      }
    }

    const crosshairVertical = svg
      .append("line")
      .attr("class", "crosshair")
      .style("stroke", "#858483")
      .style("stroke-width", 1)
      .style("display", "none");

    const crosshairHorizontal = svg
      .append("line")
      .attr("class", "crosshair")
      .style("stroke", "#858483")
      .style("stroke-width", 1)
      .style("display", "none");

    // Adding Annotations
    const maxPayoff = d3.max(data, (d) => d.y);
    const maxPayoffPoint = data.find((d) => d.y === maxPayoff);
    const minPayoff = d3.min(data, (d) => d.y);
    const minPayoffPoint = data.find((d) => d.y === minPayoff);

    const onMouseMove = (e) => {
      e.preventDefault();
      const [x, y] = d3.pointer(e);
      const xInverted = xScale.invert(x);
      const yInverted = yScale.invert(y);
      const underlyingPriceX =
        Math.abs(xInverted) < 1
          ? xScale.invert(x).toFixed(2)
          : Math.round(xScale.invert(x));
      const payoff =
        Math.abs(yInverted) < 1
          ? yScale.invert(y).toFixed(2)
          : Math.round(yScale.invert(y));

      const xTooltip = xTooltipRef.current;
      const yTooltip = yTooltipRef.current;

      // Update vertical crosshair
      crosshairVertical
        .style("display", "block")
        .attr("x1", x)
        .attr("x2", x)
        .attr("y1", 0)
        .attr("y2", HEIGHT);

      // Update horizontal crosshair
      crosshairHorizontal
        .style("display", "block")
        .attr("x1", 0)
        .attr("x2", WIDTH)
        .attr("y1", y)
        .attr("y2", y);

      // Display and update the xTooltip (showing underlying price)
      xTooltip.style.display = "inline";
      xTooltip.textContent = `Underlying Price: $${underlyingPriceX}`;

      // Adjust the xTooltip position
      const adjustedX = x - xTooltip.clientWidth / 2 + MARGIN.LEFT;
      xTooltip.style.left = adjustedX + "px";
      xTooltip.style.top = HEIGHT + MARGIN.TOP + 20 + "px";

      // Display and update the yTooltip (showing P&L)
      yTooltip.style.display = "inline";

      yTooltip.textContent = `P&L: $${Math.round(payoff * multiplier)}`;

      // Adjust the yTooltip position
      const adjustedY = y - yTooltip.clientHeight / 2 + MARGIN.TOP;
      yTooltip.style.left = MARGIN.LEFT - yTooltip.clientWidth - 10 + "px";
      yTooltip.style.top = adjustedY + "px";
    };

    const onMouseOut = (e) => {
      e.preventDefault();

      // Hide the crosshair and tooltips when the mouse moves out of the chart area
      crosshairVertical.style("display", "none");
      crosshairHorizontal.style("display", "none");
      xTooltipRef.current.style.display = "none";
      yTooltipRef.current.style.display = "none";
    };
    svg
      .append("rect")
      .attr("class", "overlay")
      .attr("width", WIDTH)
      .attr("height", HEIGHT)
      .attr("fill", "none")
      .attr("cursor", "crosshair")
      .style("pointer-events", "all")
      .on("mousemove", onMouseMove)
      .on("touchstart", onMouseMove)
      .on("mouseout", onMouseOut)
      .on("touchend", onMouseOut);

    svg
      .append("text")
      .attr("class", "x-label")
      .attr("x", WIDTH / 2)
      .attr("font-size", "12px")
      .attr("fill", "#aeafb0")
      .attr("y", HEIGHT + 35)
      .attr("text-anchor", "middle")
      .attr("pointer-events", "none")
      .text("Underlying Price");

    svg
      .append("text")
      .attr("class", "y-label")
      .attr("x", -HEIGHT / 2)
      .attr("font-size", "12px")
      .attr("fill", "#aeafb0")
      .attr("y", -MARGIN.LEFT + 20)
      .attr("text-anchor", "middle")
      .attr("transform", "rotate(-90)")
      .attr("pointer-events", "none")
      .text("Payoff");
  };

  useEffect(() => {
    if (loading === true) return;
    const chartParent = chartParentRef.current;
    if (chartParent) {
      setWidth(chartParent.clientWidth);
      setHeight(chartParent.clientHeight - 50);
    }

    try {
      const resizeObserver = new ResizeObserver((entries) => {
        const chartParent = entries[0].target;
        setWidth(chartParent.clientWidth);
        setHeight(chartParent.clientHeight - 50);
      });

      resizeObserver.observe(chartParent);

      return () => {
        resizeObserver.unobserve(chartParent);
      };
    } catch (err) {
      console.log(err);
    }
  }, [props.trades, loading, props.underlyingPrice]);

  useEffect(() => {
    if (loading === true) return;
    try {
      if (width && height) {
        drawChart();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    } catch (err) {
      console.log(err);
    }
  }, [
    width,
    height,
    selectedStrategyLegs,
    props.trades,
    loading,
    props.underlyingPrice,
  ]);

  if (loading === true) {
    return <Spinner />;
  }

  if (!hasOptions) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="90%"
      >
        <Text fontSize="lg">No open positions selected</Text>
      </Box>
    );
  }

  return (
    <Box ref={chartParentRef} display="flex" width="100%" height="100%">
      <Box ref={chartAreaRef} display="flex" />
      <Box
        id="x-axis-tooltip"
        ref={xTooltipRef}
        position="absolute"
        display="none"
        p="2px 4px"
        width="auto"
        bg="#3b87eb"
        flexWrap="wrap"
        color="white"
        fontSize="12px"
        borderRadius="2px"
        overflow="auto"
        overflowWrap="break-word"
        whiteSpace="pre-wrap"
        wordWrap="break-word"
      />
      <Box
        id="y-axis-tooltip"
        ref={yTooltipRef}
        position="absolute"
        display="none"
        p="2px 4px"
        width="auto"
        bg="#3b87eb"
        flexWrap="wrap"
        color="white"
        fontSize="12px"
        borderRadius="2px"
        overflow="auto"
        overflowWrap="break-word"
        whiteSpace="pre-wrap"
        wordWrap="break-word"
      />

      {/* Tooltip for the statistics */}
      <Box
        ref={statsTooltipRef}
        position="absolute"
        display="none"
        p="4px 8px"
        bg="#333"
        color="white"
        borderRadius="4px"
        fontSize="12px"
      />
    </Box>
  );
};

export default PayoffChart;
