import { Box, HStack, Heading, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { ChevronRight, StockLogo } from "../../design_library";
import NavWrapper from "./NavWrapper";

export default function PositionSingleExplainerTutorial() {
  return (
    <NavWrapper
      previousEnabled={false}
      isFinalStep={false}
      nextDisabled={false}
    >
      <Heading
        as="h2"
        fontSize="18px"
        mb={4}
        fontWeight={600}
        w="100%"
        textAlign={"left"}
      >
        Positions
      </Heading>
      <Text mt="10px">
        Positions are the lowest level of the account hierarchy. They represent
        the individual trades that make up the account.{" "}
      </Text>
      <Text mt="10px">
        Positions are made up from transactions. You can see the transactions by
        double clicking any group and viewing the pane at the bottom of the
        screen.
      </Text>
      <Text mt="10px">
        An open position will have a quantity greater or less than 0 which is
        the second 'Pill' in the row.
      </Text>
      <Text mt="10px">
        You can right-click on a position to see the options available to you.
      </Text>

      <VStack alignItems={"flex-start"}>
        <HStack>
          <Text>Mobile:</Text>
          <span style={{ color: "var(--accent)" }}>
            Tap and hold a position to bring up a menu of options
          </span>
        </HStack>
        <HStack>
          <Text>Desktop:</Text>
          <span style={{ color: "var(--accent)" }}>
            You can drag and drop positions between groups to re-allocate them.
            You can also drag positions into any other group.
          </span>
        </HStack>

        <Text> Click next to continue.</Text>
      </VStack>

      <Box w="100%" maxH="300px" overflow={"hidden"}>
        <Image
          mt="10px"
          src="https://cdn.wealthbee.io/main/app/tutorial/position/dnd.gif"
          alt="Account Manager"
        />
      </Box>
    </NavWrapper>
  );
}
