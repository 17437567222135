import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  IconButton,
  Image,
  Text,
} from "@chakra-ui/react";
import { MobileDrawer } from "./MobileDrawer";
import { useNavigate } from "react-router";
import { SubscriptionStatus } from "../../components/subscription/SubscriptionStatus";
import Uploads from "../uploads/Uploads";
import { MdSettings } from "react-icons/md";
import { useAtomValue } from "jotai";
import { AppStateAtom } from "../../store";
import { DiscordHoverInvite } from "../../components/misc/DiscordHoverInvite";
import React from "react";
import {
  RiAdminLine,
  RiCalendar2Fill,
  RiCashFill,
  RiDashboardFill,
  RiExchangeBoxFill,
  RiPriceTag2Fill,
  RiRoadMapFill,
} from "react-icons/ri";
import DiscordInvite from "../../components/misc/DiscordInvite";
import AccountSelector from "../../components/account/AccountSelector";

export const Navbar = () => {
  const navigate = useNavigate();
  const appState = useAtomValue(AppStateAtom);
  let logoUrl = "https://cdn.wealthbee.io/main/logos/wealthbee-logo-only.svg";

  return (
    <Box borderBottomWidth="1px" className="navbar-hook">
      <Box py="1" px="2">
        <HStack justify="space-between" className="navbar-hook-child">
          <HStack spacing="1" className="navbar-hook-container">
            <HStack spacing="1">
              <MobileDrawer />
              <Image
                className="navbar-hook-logo"
                src={logoUrl}
                alt="WealthBee"
                maxW={"50px"}
                pl="10px"
                overflow={"hidden"}
                onClick={() => {
                  navigate("/app/dashboard");
                }}
                pb="1"
                cursor="pointer"
                display={{ base: "none", md: "flex" }}
              />
            </HStack>
            <ButtonGroup
              variant="text"
              colorScheme="gray"
              display={{ base: "none", sm: "flex" }}
            >
              <Button
                className={
                  window.location.pathname === "/app/dashboard"
                    ? "navbar-hook-menu-item-base navbar-hook-menu-item-selected"
                    : "navbar-hook-menu-item navbar-hook-menu-item-base"
                }
                onClick={() => {
                  navigate("/app/dashboard");
                }}
                color={
                  window.location.pathname === "/app/dashboard"
                    ? "var(--accent)"
                    : ""
                }
                fontSize={{
                  base: "30px",
                  lg: "16px",
                  sm: "20px",
                }}
              >
                <RiDashboardFill />
                <Text
                  pl="8px"
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                >
                  Dashboard
                </Text>
              </Button>
              <Button
                className={
                  window.location.pathname === "/app/positions"
                    ? "navbar-hook-menu-item-base navbar-hook-menu-item-selected"
                    : "navbar-hook-menu-item navbar-hook-menu-item-base"
                }
                onClick={() => {
                  navigate("/app/positions");
                }}
                color={
                  window.location.pathname === "/app/positions"
                    ? "var(--accent)"
                    : ""
                }
                fontSize={{
                  base: "30px",
                  lg: "16px",
                  sm: "20px",
                }}
              >
                <RiRoadMapFill />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                  pl="8px"
                >
                  Positions
                </Text>
              </Button>

              <Button
                className={
                  window.location.pathname === "/app/transactions"
                    ? "navbar-hook-menu-item-base navbar-hook-menu-item-selected"
                    : "navbar-hook-menu-item navbar-hook-menu-item-base"
                }
                onClick={() => {
                  navigate("/app/transactions");
                }}
                color={
                  window.location.pathname === "/app/transactions"
                    ? "var(--accent)"
                    : ""
                }
                fontSize={{
                  base: "30px",
                  lg: "16px",
                  sm: "20px",
                }}
              >
                <RiExchangeBoxFill />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                  pl="8px"
                >
                  Transactions
                </Text>
              </Button>

              <Button
                className={
                  window.location.pathname === "/app/cashflows"
                    ? "navbar-hook-menu-item-base navbar-hook-menu-item-selected"
                    : "navbar-hook-menu-item navbar-hook-menu-item-base"
                }
                onClick={() => {
                  navigate("/app/cashflows");
                }}
                color={
                  window.location.pathname === "/app/cashflows"
                    ? "var(--accent)"
                    : ""
                }
                fontSize={{
                  base: "30px",
                  lg: "16px",
                  sm: "20px",
                }}
              >
                <RiCashFill />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                  pl="8px"
                >
                  Cashflows
                </Text>
              </Button>
              <Button
                className={
                  window.location.pathname === "/app/calendar"
                    ? "navbar-hook-menu-item-base navbar-hook-menu-item-selected"
                    : "navbar-hook-menu-item navbar-hook-menu-item-base"
                }
                onClick={() => {
                  navigate("/app/calendar");
                }}
                color={
                  window.location.pathname === "/app/calendar"
                    ? "var(--accent)"
                    : ""
                }
                fontSize={{
                  base: "30px",
                  lg: "16px",
                  sm: "20px",
                }}
              >
                <RiCalendar2Fill />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                  pl="8px"
                >
                  Calendar
                </Text>
              </Button>
              <Button
                className={
                  window.location.pathname === "/app/prices"
                    ? "navbar-hook-menu-item-base navbar-hook-menu-item-selected"
                    : "navbar-hook-menu-item navbar-hook-menu-item-base"
                }
                onClick={() => {
                  navigate("/app/prices");
                }}
                color={
                  window.location.pathname === "/app/prices"
                    ? "var(--accent)"
                    : ""
                }
                fontSize={{
                  base: "30px",
                  lg: "16px",
                  sm: "20px",
                }}
              >
                <RiPriceTag2Fill />
                <Text
                  display={{
                    base: "none",
                    lg: "block",
                  }}
                  pl="8px"
                >
                  Prices
                </Text>
              </Button>
              {/* Admin menu */}
              {appState.role === "admin" && (
                <Button
                  onClick={() => {
                    navigate("/app/admin");
                  }}
                  pos="fixed"
                  bottom="0px"
                  left="0px"
                  zIndex={99999}
                  opacity={0.1}
                >
                  <RiAdminLine />
                </Button>
              )}
            </ButtonGroup>
            {/* <Box minW="90px">
              <DiscordInvite />
            </Box> */}
          </HStack>
          <HStack>
            {/* <DiscordHoverInvite /> */}
            <Box display={{ base: "none", md: "flex" }}>
              <AccountSelector />
            </Box>

            <SubscriptionStatus />

            <Box
              display={{
                base: "none",
                lg: "block",
              }}
            >
              <IconButton
                display={{ base: "none", md: "flex" }}
                aria-label="Settings"
                h="60px"
                icon={<MdSettings />}
                cursor={"pointer"}
                onClick={() => {
                  navigate("/app/settings");
                }}
                className="navbar-hook-settings"
              />
            </Box>
          </HStack>
        </HStack>
      </Box>
    </Box>
  );
};
