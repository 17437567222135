import { APIEndpoint } from ".";
import { AuditResult } from "../types";

const token = localStorage.getItem("token");

export const fetchPositionAudit = async (
  accountId: number,
  underlyingName: string,
  positionGroup: string
): Promise<AuditResult[]> => {
  const result = await fetch(
    `${APIEndpoint}/positions/audit?underlying=${underlyingName}&positionGroup=${positionGroup}`,
    {
      method: "GET",
      headers: {
        Authorization: token || "",
        AccountId: accountId as any,
        "Content-Type": "application/json",
      },
    }
  );

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      const entries = data.data.map((x: AuditResult) => {
        return { ...x } as AuditResult;
      });

      return entries;
    }
  }

  return [];
};
