import { Box, Image, Text } from "@chakra-ui/react";

const generateLogoUrl = (stock) =>
  `https://cdn.wealthbee.io/logos/${stock.toUpperCase()}.png`;

const FallBack = ({ stock, size }) => (
  <Box w={`${size}px`} h={`${size}px`} borderRadius="5px">
    <Text
      display="flex"
      w={`${size}px`}
      h={`${size}px`}
      backgroundColor="var(--bg)"
      justifyContent="center"
      alignItems="center"
      borderRadius="4px"
      textAlign="center"
      verticalAlign="middle"
      lineHeight="24px"
      fontSize="16px"
      color="var(--gray)"
      textOverflow="ellipsis"
    >
      {stock ? stock[0].toUpperCase() : "0"}
    </Text>
  </Box>
);

export const StockLogo = ({ stock, size = 30 }) => {
  if (!stock) return null;

  const logoUrl = generateLogoUrl(stock);

  return (
    <Image
      htmlHeight={size}
      alt={`${stock} logo`}
      htmlWidth={size}
      src={logoUrl}
      borderRadius="30px"
      fallback={<FallBack stock={stock} size={size} />}
    />
  );
};
