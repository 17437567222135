import {
  Avatar,
  AvatarGroup,
  Box,
  Center,
  Flex,
  HStack,
  Heading,
  Image,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Meta, OuterContainer } from "../../../components";
import { FaRegCreditCard } from "react-icons/fa";
import moment from "moment";

/* eslint-disable @typescript-eslint/no-unused-vars */
export const AuthenticationFrame = (props: any) => {
  return (
    <div>
      <Flex
        minH={{ base: "100vh", md: "100vh" }}
        bgGradient={{
          md: "linear(to-r, bg.accent.default 50%, bg.surface 50%)",
        }}
        backgroundColor={"var(--black)"}
      >
        <OuterContainer />

        <Flex maxW="full" width="full">
          <Box flex="1" display={{ base: "none", lg: "block" }}>
            <Flex
              direction="column"
              px={{ base: "4", md: "8" }}
              height="full"
              color="fg.accent.default"
            >
              <Flex
                flex="1"
                align="center"
                height="full"
                justifyContent={"center"}
                // pt="50px"
              >
                <Stack spacing="8" w="lg">
                  <Stack spacing="6">
                    <a href="../../">
                      <Flex align="center" h="24">
                        <Image
                          src="https://cdn.wealthbee.io/main/logos/wealthbee-logo-with-text.svg"
                          maxW={"355px"}
                          zIndex={999}
                        />
                      </Flex>
                    </a>
                    <Heading
                      as="h3"
                      fontSize="16px"
                      pt="40px"
                      color={"var(--accent)"}
                      fontWeight="700"
                      p="5px"
                      textTransform="uppercase"
                      id="slogan"
                    >
                      All-in-one Portfolio Analytics
                    </Heading>
                    <Heading
                      paddingTop="28px"
                      as="h1"
                      fontSize={{ base: "32px", md: "48px", lg: "64px" }}
                      fontWeight="700"
                      lineHeight="1.2"
                      letterSpacing="tight"
                      color={"var(--white)"}
                      textTransform="uppercase"
                    >
                      Financial Insights that
                      <Text color={"var(--accent)"}>
                        <span className="rainbow-slow">make sense</span>
                      </Text>
                    </Heading>
                    <Text
                      fontSize={{ base: "md", md: "lgs" }}
                      maxW="md"
                      fontWeight="medium"
                      color="fg.accent.muted"
                    >
                      Stay on track for your financial targets by regularly
                      monitoring your net worth. Analyze your trades and
                      investments to make better decisions.
                    </Text>
                  </Stack>
                </Stack>
              </Flex>
            </Flex>
          </Box>
          <Center
            flex="1"
            pt="40px"
            px="12px"
            backgroundColor={"var(--dark-gray)"}
            opacity={1}
            zIndex={999}
          >
            <Box width={"md"} px="20px" zIndex={10}>
              <Box display={{ md: "block", lg: "none" }}>
                <a href="../../">
                  <Flex align="center" h="24">
                    <Image
                      src="https://cdn.wealthbee.io/main/logos/wealthbee-logo-with-text.svg"
                      maxW={"355px"}
                      zIndex={999}
                    />
                  </Flex>
                </a>
              </Box>
              {props.children}
            </Box>
          </Center>
        </Flex>
      </Flex>
    </div>
  );
};
