import {
  Box,
  VStack,
  HStack,
  Input,
  Button,
  Select,
  Text,
  Divider,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import React, { useState } from "react";
import PayoffChart from "./PayOffChart";
import { Position } from "../../types";

export default function PayOffConfigurator() {
  const [positions, setPositions] = useState<Position[]>([]);
  const [underlyingPrice, setUnderlyingPrice] = useState<number>(100); // Default underlying price
  const [newPosition, setNewPosition] = useState({
    strike: "",
    putCall: "Call",
    quantity: "",
    price: "",
    multiplier: 100, // Default multiplier
  });
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editedPosition, setEditedPosition] = useState<Position | null>(null);

  const addPosition = () => {
    if (
      newPosition.strike &&
      newPosition.quantity &&
      newPosition.price &&
      !isNaN(Number(newPosition.strike)) &&
      !isNaN(Number(newPosition.quantity)) &&
      !isNaN(Number(newPosition.price))
    ) {
      setPositions([
        ...positions,
        {
          id: `${Date.now()}`,
          instrument: {
            strike: Number(newPosition.strike),
            putCall: newPosition.putCall,
            instrumentType: "Option",
            multiplier: newPosition.multiplier,
          },
          quantity: Number(newPosition.quantity),
          price: Number(newPosition.price),
        },
      ]);

      // Reset the form
      setNewPosition({
        strike: "",
        putCall: "Call",
        quantity: "",
        price: "",
        multiplier: 100,
      });
    }
  };

  const removePosition = (id: string) => {
    setPositions(positions.filter((position) => position.id !== id));
  };

  const startEditing = (position: Position) => {
    setEditingId(position.id);
    setEditedPosition({ ...position });
  };

  const saveEdit = () => {
    if (editedPosition) {
      setPositions((prevPositions) =>
        prevPositions.map((pos) =>
          pos.id === editingId ? { ...editedPosition } : pos
        )
      );
      cancelEdit();
    }
  };

  const cancelEdit = () => {
    setEditingId(null);
    setEditedPosition(null);
  };

  return (
    <VStack spacing={6} align="stretch" padding={6}>
      {/* Underlying Price Selector */}
      <FormControl>
        <FormLabel fontSize="lg" fontWeight="bold">
          Set Underlying Price
        </FormLabel>
        <Input
          type="number"
          value={underlyingPrice}
          onChange={(e) => setUnderlyingPrice(Number(e.target.value))}
          placeholder="Enter underlying price"
        />
      </FormControl>

      {/* Add New Position */}
      <Box>
        <Text fontSize="lg" fontWeight="bold" marginBottom="2">
          Add a New Leg
        </Text>
        <HStack wrap={"wrap"}>
          <FormControl width={"200px"} minW="200px">
            <FormLabel>Strike Price</FormLabel>
            <Input
              type="number"
              placeholder="Enter strike price"
              value={newPosition.strike}
              onChange={(e) =>
                setNewPosition({ ...newPosition, strike: e.target.value })
              }
            />
          </FormControl>

          <FormControl width={"200px"} minW="200px">
            <FormLabel>Type (Call/Put)</FormLabel>
            <Select
              value={newPosition.putCall}
              onChange={(e) =>
                setNewPosition({ ...newPosition, putCall: e.target.value })
              }
            >
              <option value="Call">Call</option>
              <option value="Put">Put</option>
            </Select>
          </FormControl>

          <FormControl width={"200px"} minW="200px">
            <FormLabel>Quantity</FormLabel>
            <Input
              type="number"
              placeholder="Enter quantity"
              value={newPosition.quantity}
              onChange={(e) =>
                setNewPosition({ ...newPosition, quantity: e.target.value })
              }
            />
          </FormControl>

          <FormControl width={"200px"} minW="200px">
            <FormLabel>Price</FormLabel>
            <Input
              type="number"
              placeholder="Enter price"
              value={newPosition.price}
              onChange={(e) =>
                setNewPosition({ ...newPosition, price: e.target.value })
              }
            />
          </FormControl>

          <Button bg="var(--accent)" onClick={addPosition} mt="30px">
            Add Leg
          </Button>
        </HStack>
      </Box>

      {/* List of Current Positions */}
      <Box>
        <Text fontSize="lg" fontWeight="bold" marginBottom="2">
          Current Legs
        </Text>
        {positions.length === 0 ? (
          <Text>No legs added yet.</Text>
        ) : (
          <VStack align="start" spacing={3}>
            {positions.map((position) =>
              editingId === position.id ? (
                <HStack key={position.id} spacing={4} width="100%">
                  <Input
                    type="number"
                    value={editedPosition?.instrument.strike}
                    onChange={(e) =>
                      setEditedPosition({
                        ...editedPosition!,
                        instrument: {
                          ...editedPosition!.instrument,
                          strike: Number(e.target.value),
                        },
                      })
                    }
                  />
                  <Select
                    value={editedPosition?.instrument.putCall}
                    onChange={(e) =>
                      setEditedPosition({
                        ...editedPosition!,
                        instrument: {
                          ...editedPosition!.instrument,
                          putCall: e.target.value as "Call" | "Put",
                        },
                      })
                    }
                  >
                    <option value="Call">Call</option>
                    <option value="Put">Put</option>
                  </Select>
                  <Input
                    type="number"
                    value={editedPosition?.quantity}
                    onChange={(e) =>
                      setEditedPosition({
                        ...editedPosition!,
                        quantity: Number(e.target.value),
                      })
                    }
                  />
                  <Input
                    type="number"
                    value={editedPosition?.price}
                    onChange={(e) =>
                      setEditedPosition({
                        ...editedPosition!,
                        price: Number(e.target.value),
                      })
                    }
                  />
                  <Button size="sm" colorScheme="green" onClick={saveEdit}>
                    Save
                  </Button>
                  <Button size="sm" colorScheme="gray" onClick={cancelEdit}>
                    Cancel
                  </Button>
                </HStack>
              ) : (
                <HStack key={position.id} spacing={4} width="100%">
                  <Box>
                    Strike: {position.instrument.strike}, Type:{" "}
                    {position.instrument.putCall}, Quantity: {position.quantity}
                    , Price: {position.price}
                  </Box>
                  <Button
                    size="sm"
                    colorScheme="yellow"
                    onClick={() => startEditing(position)}
                  >
                    Edit
                  </Button>
                  <Button
                    size="sm"
                    colorScheme="red"
                    onClick={() => removePosition(position.id)}
                  >
                    Remove
                  </Button>
                </HStack>
              )
            )}
          </VStack>
        )}
      </Box>

      {/* Payoff Chart */}
      <Box
        borderWidth="1px"
        borderRadius="md"
        overflow="hidden"
        height="600px"
        padding={4}
        bg="var(--black)"
      >
        <PayoffChart
          trades={[]} // Empty because trades are not relevant for this demo
          positions={positions}
          underlyingPrice={underlyingPrice} // Chart updates automatically
        />
      </Box>
    </VStack>
  );
}
