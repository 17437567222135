import { ColDef } from "ag-grid-community";
import {
  createGroupColumn,
  ColumnTypeEnum,
  PositionGroupRender,
} from "../../../../../../../components";
import convertSymbolToReadable from "../../../../../../../components/grid/utils/convertSymbolToReadable";
import { calculateReturnOnBasis } from "../../../../../../../utils";

export const recentlyTradedPositionsColumnDefs: ColDef[] = [
  createGroupColumn("Last Trade Date", "positionDate", ColumnTypeEnum.Date, {
    sort: "asc",
    aggFunc: "distinct",
    minWidth: 140,
    hide: true,
    rowGroup: true,
  }),

  createGroupColumn(
    "Underlying Symbol",
    "underlyingSymbol",
    ColumnTypeEnum.Logo,
    {
      suppressColumnsToolPanel: true,
      hide: true,
      rowDrag: true,
    }
  ),

  createGroupColumn("Underlying", "underlyingName", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    sort: "asc",
    rowDrag: true,
    suppressColumnsToolPanel: true,
    valueGetter: (params) => {
      if (params.data.underlyingType === "Future") {
        return params.data.underlyingName;
      } else {
        return params.data.underlyingSymbol;
      }
    },
  }),

  createGroupColumn("Position Group", "positionName", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    sort: "asc",
    rowDrag: true,
    suppressColumnsToolPanel: true,
  }),

  // createGroupColumn(
  //   "Underlying Symbol",
  //   "underlyingSymbol",
  //   ColumnTypeEnum.Logo,
  //   {
  //     suppressColumnsToolPanel: true,
  //     hide: true,
  //     rowDrag: true,
  //   }
  // ),

  createGroupColumn("Avg. Price", "averagePrice", ColumnTypeEnum.Currency, {
    cellRendererParams: { noFormatting: true },
    hide: false,
    aggFunc: "none",
  }),

  createGroupColumn(
    "Gross proceeds",
    "grossProceeds",
    ColumnTypeEnum.Currency,
    { cellRendererParams: { showDirection: true }, aggFunc: "sum" }
  ),

  createGroupColumn("Net proceeds", "netProceeds", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn("Costs", "costs", ColumnTypeEnum.Currency, {
    hide: false,
    aggFunc: "sum",
    valueGetter: (params) => {
      return params.data.costs * -1;
    },
  }),

  createGroupColumn("Cost Basis", "costBasis", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn(
    "Return On Basis",
    "returnOnBasis",
    ColumnTypeEnum.Percentage,
    {
      cellRendererParams: { showDirection: true },
      aggFunc: "returnOnBasis",
      valueGetter: (params) => {
        return calculateReturnOnBasis(
          params.data.costBasis,
          params.data.realisedPnl,
          params.data.unrealisedPnl
        );
      },
    }
  ),
];
