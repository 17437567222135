import { HStack, Stat } from "@chakra-ui/react";
import StatContainer from "../../../../components/design_library/StatContainer";
import KnowledgeBaseHelper from "../../../knowledgebase/KnowledgeBaseHelper";

export const KeyStatBar = ({
  quantity,
  realisedPnl,
  netProceeds,
  unRealisedPnL,
  premiumReceived,
  costs,
  grossProceeds,
  price,
  unRealisedPnLPercentage,
  daysInPosition,
  isAggData,
  annualizedReturnPercentage,
  breakevenPoint,
  realisedPnLPercentage,
  risk,
}) => {
  return (
    <HStack
      wrap="wrap"
      justifyContent={"flex-start"}
      pt="8px"
      pb="4px"
      px="4px"
    >
      <StatContainer
        title="Open shares/contracts"
        stat={quantity}
        type="NUMERIC"
      />
      <StatContainer
        title="Realized P&L"
        stat={realisedPnl || "0.00"}
        type="CURRENCY"
      />
      <StatContainer title="Net proceeds" stat={netProceeds} type="CURRENCY" />
      <StatContainer
        title="Gross proceeds"
        stat={grossProceeds}
        type="CURRENCY"
      />

      <StatContainer
        title="Unrealized P&L"
        stat={unRealisedPnL}
        type="CURRENCY"
        change={unRealisedPnLPercentage}
        changeSuffix="%"
      />
      <StatContainer
        title="Total P&L"
        stat={realisedPnl + unRealisedPnL}
        type="CURRENCY"
      />
      <StatContainer title="Total Risk" stat={0} type="CURRENCY" />
      {/* <StatContainer
        title="Total Premium"
        stat={premiumReceived}
        type="CURRENCY"
      /> */}
      <StatContainer title="Ann. Return" stat={0} type="CURRENCY" />
      <StatContainer
        title="Total Fees"
        stat={-Math.abs(costs)}
        type="CURRENCY"
      />

      {/* <StatContainer
        title="Premium Recieved"
        stat={premiumReceived}
        type="CURRENCY"
      /> */}
      <StatContainer
        title={!isAggData ? "Days in position" : "Days in position (avg)"}
        stat={daysInPosition?.toFixed(2)}
        type="NUMERIC"
      />

      <StatContainer
        title="Annualized return"
        stat={annualizedReturnPercentage?.toFixed(2)}
        type="PERCENTAGE"
      />

      <StatContainer title="Price paid" stat={price} type="CURRENCY" />
      <StatContainer
        title="Breakeven Point"
        stat={breakevenPoint}
        type="CURRENCY"
      />

      <StatContainer title="Risk" stat={risk} type="CURRENCY" />
    </HStack>
  );
};
