//drawer that popu out and loads an article
import React, { useEffect } from "react";
import {
  Drawer,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Link,
  VStack,
  HStack,
  Text,
  Flex,
} from "@chakra-ui/react";
import { getKnowledgeBaseArticles } from "../../api";
import { BiLink, BiLogoYoutube } from "react-icons/bi";
import { MdHelp } from "react-icons/md";
import DiscordInvite from "../../components/misc/DiscordInvite";

export default function KnowledgeBaseHelper({ topic }: { topic: string }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = React.useState(true);
  const [articles, setArticles] = React.useState([]);

  const getArticles = async () => {
    const as = await getKnowledgeBaseArticles(true, topic);
    setArticles(as.articles);
    setLoading(false);
  };

  useEffect(() => {
    getArticles();
  }, []);

  if (articles.length === 0) return null;

  return (
    <>
      <Button
        onClick={() => onOpen()}
        h="100%"
        maxH="46px"
        border={"1px solid var(--gray)"}
      >
        <MdHelp color="var(--accent)" />
        <Text pl="4px" fontSize="14px">
          {" "}
          Help{" "}
        </Text>
      </Button>

      <Drawer onClose={onClose} isOpen={isOpen} size={"xs"}>
        <DrawerOverlay />
        <DrawerContent bg="var(--dark-gray)">
          <DrawerCloseButton />
          <DrawerHeader fontSize="14px">Knowledge Base: {topic}</DrawerHeader>
          <VStack
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            pl="24px"
          >
            {articles &&
              articles
                .sort((a, b) => {
                  return a.title.localeCompare(b.title);
                })
                .map((article) => (
                  <HStack fontSize="14px">
                    <BiLink />

                    <Link
                      maxW="250px"
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      color="var(--accent)"
                      key={article.id}
                      target="_blank"
                      href={`https://wealthbee.io/knowledgebase/${article.slug}`}
                    >
                      {article.title}
                    </Link>
                  </HStack>
                ))}

            <VStack mt="20px" spacing={2} direction="column" align="start">
              <Text fontSize="14px">More Help Needed?</Text>
              <Link
                key="youtube"
                target="_blank"
                href="https://www.youtube.com/@WealthBeeIo"
              >
                <HStack>
                  <BiLogoYoutube size="40px" color="red" />
                  <Text textDecoration={{ underline: "none" }}>YouTube</Text>
                </HStack>
              </Link>

              <DiscordInvite />
            </VStack>
          </VStack>
        </DrawerContent>
      </Drawer>
    </>
  );
}
