import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import { navigate } from "./utils/constants";
import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import {
  LeftArrowIcon,
  RightArrowIcon,
} from "../../components/design_library/Icons";
import currencyFormatter from "currency-formatter";

class Toolbar extends React.Component {
  render() {
    let {
      localizer: { messages },
      label,
    } = this.props;

    return (
      <HStack mb="2" justifyContent={"flex-start"}>
        <HStack justifyContent={"space-between"} w="full">
          <HStack>
            <span className="rbc-btn-group">
              <Button
                className="journal-button-today"
                onClick={this.navigate.bind(null, navigate.TODAY)}
              >
                {messages.today}
              </Button>
              <Button
                className="journal-button-previous"
                onClick={this.navigate.bind(null, navigate.PREVIOUS)}
              >
                <LeftArrowIcon />
              </Button>
              <Button
                className="journal-button-next"
                onClick={this.navigate.bind(null, navigate.NEXT)}
              >
                <RightArrowIcon />
              </Button>
            </span>

            <span className="rbc-toolbar-label">{label}</span>

            <span className="rbc-btn-group">
              {this.viewNamesGroup(messages)}
            </span>
          </HStack>

          <HStack spacing="8" mr="10px">
            <VStack
              justifyContent={"flex-end"}
              textAlign={"left"}
              alignItems={"flex-end"}
            >
              <Text fontSize="sm" opacity="0.7">
                Traded days
              </Text>

              <Box>
                <Text fontSize="xl">{this.props.numberOfTradedDays} days</Text>
              </Box>
            </VStack>
            <VStack
              justifyContent={"flex-end"}
              textAlign={"left"}
              alignItems={"flex-end"}
            >
              <Text fontSize="sm" opacity="0.7">
                Monthly P&L
              </Text>

              <Box
                px="2"
                borderRadius={"2xl"}
                bg={this.props.pnlThisMonth > 0 ? "var(--green)" : "var(--red)"}
              >
                <Text fontSize="xl">
                  <span>
                    {currencyFormatter.format(this.props.pnlThisMonth, {
                      code: "USD",
                      precision: 0,
                    })}
                  </span>
                </Text>
              </Box>
            </VStack>
          </HStack>
        </HStack>
      </HStack>
    );
  }

  navigate = (action) => {
    this.props.onNavigate(action);
  };

  view = (view) => {
    this.props.onView(view);
  };

  viewNamesGroup(messages) {
    let viewNames = this.props.views;
    const view = this.props.view;

    if (viewNames.length > 1) {
      return viewNames.map((name) => (
        <Button
          key={name}
          className={clsx({ "rbc-active": view === name })}
          onClick={this.view.bind(null, name)}
        >
          {messages[name]}
        </Button>
      ));
    }
  }
}

Toolbar.propTypes = {
  view: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
};

export default Toolbar;
