import React from "react";
import { Button, HStack, Icon, Text, Tooltip } from "@chakra-ui/react";
import { CustomToolTip, InfoFill, MoveIcon } from "../design_library";
import { CloseIcon, InfoIcon } from "@chakra-ui/icons";
import { InsightTemplate } from "../../types/Insight";
import { useLayout } from "../../features/dashboard/helpers/Layout";
import { BiHelpCircle } from "react-icons/bi";
import { IoHelp, IoHelpOutline } from "react-icons/io5";
import { FiHelpCircle } from "react-icons/fi";
import { HiOutlinePhoneIncoming } from "react-icons/hi";

type InsightHeaderProps = {
  insight: InsightTemplate;
  preview?: boolean;
  embedded?: boolean;
};

export const InsightHeader: React.FC<InsightHeaderProps> = ({
  insight,
  preview,
  embedded,
}) => {
  const layout = useLayout();

  if (embedded) {
    return (
      <HStack
        w="full"
        justifyContent="space-between"
        px="5px"
        h="24px"
      ></HStack>
    );
  }

  return (
    <HStack w="full" justifyContent="space-between" px="5px" h="24px">
      <HStack>
        <Icon
          className="drag-handle"
          stroke="var(--light-gray)"
          backgroundColor="var(--bg)"
          strokeWidth={4}
          as={MoveIcon}
          cursor="grab"
        />
        <Text fontSize="10px" color="var(--menu-bar)">
          {insight.title}
        </Text>
      </HStack>

      <HStack transform={"translateX(12px) translateY(0px)"}>
        <Tooltip label={insight.description}>
          <Icon
            as={InfoIcon}
            boxSize={4}
            color="var(--light-gray)"
            transform={"translateX(12px) translateY(0px)"}
          />
        </Tooltip>

        <Button
          bg="transparent"
          zIndex={2}
          fontSize="12px"
          color="var(--light-gray)"
          onClick={(e) => {
            if (!preview) {
              return layout.removeInsightFromLayouts(insight);
            }
          }}
        >
          <CloseIcon />
        </Button>
      </HStack>
    </HStack>
  );
};
